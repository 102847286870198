import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box, Avatar, Chip } from '@mui/material';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import { useTranslation } from 'react-i18next';

export default function OrderItem({ orderType, lineItem }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const {
    id: lineItemId,
    product,
    quantity,
    status,
    productInfo
  } = lineItem;

  const { imgUrl, name, price, discount } = product;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      mb={1}
      sx={{
        width: '100%'
      }}
    >
      <Box sx={{ borderRadius: 0 }}>
        <Box display="flex">
          <Box
            display="flex"
            alignItems="space-between"
            ml={1}
            mr={2}
            width="100%"
          >
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mt={1}
                ml={1}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                
                  <Typography
                    sx={{
                      fontWeight: 'fontWeightBold',
                      width: !matches ? '65vw' : '25vw',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    variant="h6"
                  >
                    {name}
                  </Typography>
                </Box>

                <Box>
                  <Box>
                    
                    <Typography color="textSecondary" variant="subtitle">
                      {((100 - (discount || 0)) * price) / 100} {t('currency')} x{' '}
                      {quantity} {t('units')} ={' '}
                      {(
                        Math.round(
                          quantity *
                            (((100 - (discount || 0)) * price) / 100) *
                            100
                        ) / 100
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {orderType === 'TABLE' && (
                <Box>
                  <Box display="flex">
                    <Box ml={1} mr={1} mb={1}>
                      <Chip
                        size="small"
                        variant="outlined"
                        clickable={true}
                        style={{
                          backgroundColor:
                            status === 'TO_DELIVER'
                              ? red[400]
                              : status === 'CANCELED'
                              ? '#E8E8E8'
                              : green[700],
                          border: '0px',
                          marginRight: '5px',
                          fontWeight: 'bold',
                          color: status === 'CANCELED' ? '#000' : '#fff',
                          fontSize: '12px'
                        }}
                        key={'status'}
                        label={
                          status === 'TO_DELIVER'
                            ? t('statusPreparing')
                            : status === 'CANCELED'
                            ? t('statusCanceled')
                            : t('statusDelivered')
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box mr={2} mt={2} mb={2}>
            {(!product.imgUrl ||
            product.imgUrl ===
              'http://res.cloudinary.com/daqirrhrb/image/upload/v1634771923/pn6dqajidofigjpiop1u.png' ||
            product.imgUrl ===
              'https://ik.imagekit.io/lprqlslc6bt/cloudinary-images/pn6dqajidofigjpiop1u.png') ? (
              null
            ) : (
              <Avatar
                sx={{
                  position: 'relative',
                  borderRadius: 2,
                  zIndex: 0,
                  width: !matches ? '20vw' : '5vw',
                  height: !matches ? '25vw' : '6vw'
                }}
                src={imgUrl}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}