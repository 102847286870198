import React from "react";
import { Paper, Avatar } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ScrollMenu } from "react-horizontal-scrolling-menu";
import useDrag from "./useDrag";
import "./NoScrollBar.css";

import { useCart } from "../../context/cart/context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ScrollableProducts({
  restaurant,
  restaurantId,
  restaurantKey,
  tableId,
  hasOrderModule,
  replaceRecomandedText = "recommendedTitle"
}) {
  const { state, dispatch } = useCart();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  let products = [];
  restaurant?.categories.forEach((c) => c.products.forEach((p) => products.push(p)));

  products = products
    .filter((p) => p.tags.some((tag) => tag.value === "Recomandate"))
    .filter((p) => restaurantKey || !p.tags.some((t) => t.value === "DeliveryOnly"))
    .filter((p) => p.available || p.available === undefined);

  function handleOnClick(product) {
    const isAvailable = product.available || product.available === undefined;

    if (isAvailable) {
      dispatch({ type: "setPosition", payload: window.pageYOffset });
      history.push({
        pathname:
          (restaurantKey
            ? `/${restaurantKey}`
            : `/restaurants/${restaurantId}/tables/${tableId}`) +
          `/dish-details/${product.id}`,
        state: { product, hasOrderModule }
      });
    }
  }

  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag = ({ scrollContainer }) => (ev) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  return (
    <>
      {products.length !== 0 && (
        <Box mt={1} mb={1}>
          <Typography mb={2} fontWeight="bold" variant="h5">
            {t(replaceRecomandedText)}
          </Typography>

          <div onMouseLeave={dragStop}>
            <ScrollMenu
              onWheel={onWheel}
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
              scrollContainerClassName="NoScrollBar"
            >
              {products.map((product) => (
                <Box
                  key={product.id}
                  onClick={() => handleOnClick(product)}
                  sx={{ width: !matches ? "41vw" : "10vw" }}
                  mr={1}
                >
                  <Paper
                    sx={{ boxShadow: "10px" }}
                    elevation={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ backgroundColor: "white", borderRadius: 100 }}
                    >
                      <Avatar
                        sx={{
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                          borderBottomRightRadius: 10,
                          borderBottomLeftRadius: 10,
                          zIndex: 0,
                          width: "100%",
                          height: !matches ? "30vw" : "10vw"
                        }}
                        src={product.imgUrl + "?tr=h-300"}
                      />
                    </Box>

                    <Box>
                      <Box mt={1}>
                        <Typography
                          sx={{
                            fontWeight: "fontWeightBold",
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                          variant="body1"
                        >
                          {product.name}
                        </Typography>
                      </Box>

                      <Box display="flex">
                        <Box mr={1}>
                          {!product.discount || product.discount === 0 ? (
                            <Typography variant="body1">
                              {product.price} {t("currency")}
                            </Typography>
                          ) : (
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <Typography
                                mr={1}
                                sx={{ color: "#bbbbbb", textDecorationLine: "line-through" }}
                                variant="body1"
                              >
                                {product.price} {t("currency")}
                              </Typography>

                              <Typography mr={1} sx={{ color: "red" }} variant="body1">
                                {((100 - product.discount) * product.price) / 100}{" "}
                                {t("currency")}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              ))}
            </ScrollMenu>
          </div>
        </Box>
      )}
    </>
  );
}

function onWheel(apiObj, ev) {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
