import React from "react";
import throttle from "lodash/throttle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import CategoriesMenu from './CategoriesMenu';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCart } from '../context/cart/context';
import { backendUrl } from "../index";

import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------

const tabHeight = 69;

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;
  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }
  return makeUnique(hash, unique, i + 1);
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
  })
);

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};

const noop = () => {};

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined;

    window.addEventListener("scroll", throttledCallback);
    return () => {
      window.removeEventListener("scroll", throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}

function ScrollSpyTabs(props) {
  const { state } = useCart();
  const { tabsInScroll, between, loading, tableId, restaurantId, restaurantKey } = props;
  
  const { t } = useTranslation();

  const [show, setShow] = React.useState(false);
  const mapHashToId = {};

  function setActiveState(hash) {
    setActiveStateValue(hash);
  }
  const [activeState, setActiveStateValue] = React.useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  let itemsServer = tabsInScroll?.map((tab, index) => {
    const hash = textToHash(tab.text);
    mapHashToId[hash] = index;
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash)
    };
  });

  const itemsClientRef = React.useRef([]);
  React.useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = React.useRef(false);
  const unsetClickedRef = React.useRef(null);

  const findActiveIndex = React.useCallback(() => {
    if (window.pageYOffset > 0.85 * window.innerHeight) {
      setShow(true);
    } else {
      setShow(false);
    }

    if (activeState === null && itemsServer && itemsServer.length > 0) {
      setActiveState(itemsServer[0].hash);
    }

    if (clickedRef.current) return;

    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null };
        break;
      }
      const item = itemsClientRef.current[i];
      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8 +
            tabHeight
      ) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  useThrottledOnScroll(itemsServer?.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash) => () => {
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);
      if (window) {
        window.scrollTo({
          top:
            document.getElementById(hash).getBoundingClientRect().top +
            window.pageYOffset -
            Math.ceil((window.innerHeight * 10) / 100),
          behavior: "smooth"
        });
      }
    }
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(unsetClickedRef.current);
    };
  }, []);

  return (
    <div>
      {show && itemsServer && (
        <AppBar
          sx={{
            backgroundColor: "#fff",
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            width: "100%"
          }}
        >
          <Toolbar>
            <CategoriesMenu
              handleChange={handleClick}
              selected={activeState}
              options={itemsServer}
            />
            <Tabs
              variant="scrollable"
              value={activeState ? activeState : itemsServer[0].hash}
            >
              {itemsServer.map((item2) => (
                <StyledTab
                  sx={{ height: "8vh", fontSize: "2.5vh" }}
                  key={item2.hash}
                  label={item2.text}
                  onClick={handleClick(item2.hash)}
                  value={item2.hash}
                />
              ))}
            </Tabs>
          </Toolbar>
          <div />
        </AppBar>
      )}

      {between}

      {loading && (
        <Box m={1}>
          <Skeleton sx={{ marginTop: 3 }} width={!matches ? "50vw" : "33vw"} height="4vh" variant="rectangular" />
          <Skeleton height="25vh" animation="wave" />
          <Box mt={2}>
            {t('loadingMessage')}
          </Box>
        </Box>
      )}

      {!itemsServer && (
        <Box m={1}>
          <Skeleton sx={{ marginTop: 3 }} width={!matches ? "50vw" : "33vw"} height="4vh" variant="rectangular" />
          <Skeleton height="25vh" animation="wave" />
          <Box mt={2}>
            {t('noTabsFound')}
          </Box>
        </Box>
      )}

      {!loading && (
        <div className="container">
          {itemsServer?.map((item1) => (
            <article id={item1.hash} key={item1.hash}>
              {item1.component}
            </article>
          ))}
        </div>
      )}
    </div>
  );
}

export default ScrollSpyTabs;
