import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";

import { useTranslation } from "react-i18next";

function MyAutoComplete(props) {
  const { t } = useTranslation();

  const [firstTouch, setFirstTouch] = useState(false);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw",
    onPlaceSelected: (place) => {
      props.addplace(place);
      props.setFirst(true);
    },
    inputAutocompleteValue: "address",
    options: {
      types: ["establishment", "geocode"],
      componentRestrictions: { country: "ro" }
    }
  });

  return (
    <div className="App">
      <Box mb={2}>
        <TextField
          autoComplete="off"
          label={t('address')}
          required
          onFocus={() => {
            props.setFirst(false);
            materialRef.current.value = "";
          }}
          multiline
          fullWidth
          variant="standard"
          inputRef={materialRef}
          error={props.error}
          helperText={props.helperText}
          {...props.otherProps}
          value={props.first ? props.address : null}
        />
      </Box>
    </div>
  );
}

export default MyAutoComplete;
