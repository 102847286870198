import React, { useState, useEffect } from 'react';
import './AllRestaurantsMarker.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function isRestaurantOpen(restaurant) {
  if (!restaurant.programEntries) return false;

  const now = new Date();
  const dayOfWeek = now.getDay();

  const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const currentDayName = dayNames[dayOfWeek];

  const beginKey = `${currentDayName}Begin`;
  const endKey   = `${currentDayName}End`;

  const beginValue = restaurant.programEntries[beginKey];
  const endValue   = restaurant.programEntries[endKey];

  if (!beginValue || !endValue) {
    return false;
  }

  function toMinutes(hhmm) {
    const [hh, mm] = hhmm.split(':').map(Number);
    return hh * 60 + mm;
  }

  const nowMinutes = now.getHours() * 60 + now.getMinutes();
  const beginMinutes = toMinutes(beginValue);
  const endMinutes   = toMinutes(endValue);

  return (nowMinutes >= beginMinutes && nowMinutes < endMinutes);
}

const AllRestaurantsMarker = ({
  restaurant,
  isCluster,
  clusterCount,
  isActive,
  onClick
}) => {
  const { t } = useTranslation();

  const [showInfo, setShowInfo] = useState(isActive);
  const history = useHistory();

  useEffect(() => {
    setShowInfo(isActive);
  }, [isActive]);

  const handleClick = () => {
    if (isActive) {
      onClick(null);
    } else {
      onClick(restaurant.id);
    }
  };

  const handleNavigate = () => {
    history.push(restaurant.urlKey);
  };

  const openNow = isRestaurantOpen(restaurant);
  const pinClass = `Mappin ${openNow ? 'open' : 'closed'}`;

  return (
    <>
      {!isCluster ? (
        <div className={pinClass} onClick={handleClick} data-testid="marker">
          <img
            src={restaurant.imageUrl}
            alt={restaurant.name}
            className="restaurant-logo"
            data-testid="restaurant-logo"
          />
        </div>
      ) : (
        <div className="ClusterMarker" data-testid="cluster-marker" onClick={handleClick}>
          <div className="ClusterCircle">{clusterCount}</div>
        </div>
      )}

      {showInfo && (
        <div className="card" data-testid="card-info">
          <img
            src={restaurant.imageUrl}
            alt={restaurant.name}
            className="restaurant-image"
            data-testid="restaurant-image"
          />
          <div className="card-info">
            <p>{restaurant.description}</p>
            <button onClick={handleNavigate} className="details-button" data-testid="see-details-button">
              {t('seeDetails')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AllRestaurantsMarker;
