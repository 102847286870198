import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <div
      style={{ display: 'flex', flexdirection: 'column', alignItems: 'center' }}
    >
      <h2>{t('notFoundTitle')}</h2>
      <p>{t('notFoundMessage')}</p>
    </div>
  );
}
