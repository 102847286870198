import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box, Avatar, Fab } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useFeature, MODULE_ORDER, COLOR } from '../context/feature/context';
import { useCart } from '../context/cart/context';

import { useTranslation } from 'react-i18next';

export default function CartItem({ product }) {
  const { state, dispatch } = useCart();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const { state: featureState } = useFeature();
  const color = featureState.features.hasOwnProperty(COLOR) ? featureState.features[COLOR] : '#000';
  const { id, title, quantity, imgUrl, price, productInfo } = product;
  const [updated, setUpdated] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mb={1} sx={{ width: '100%' }}>
      <Paper sx={{ borderRadius: 0 }} elevation={0}>
        <Box display="flex">
          <Box ml={2} mt={2} mb={2}>
            {(!product.imgUrl ||
              product.imgUrl ===
                'http://res.cloudinary.com/daqirrhrb/image/upload/v1634771923/pn6dqajidofigjpiop1u.png' ||
              product.imgUrl ===
                'https://ik.imagekit.io/lprqlslc6bt/cloudinary-images/pn6dqajidofigjpiop1u.png') ? (
              null
            ) : (
              <Avatar
                sx={{
                  position: 'relative',
                  borderRadius: 2,
                  zIndex: 0,
                  width: !matches ? '20vw' : '5vw',
                  height: !matches ? '25vw' : '6vw'
                }}
                src={imgUrl}
              />
            )}
          </Box>

          <Box
            width="100%"
            ml={1}
            mr={1}
            flexDirection="column"
            display="flex"
            alignItems="space-between"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="space-between" justifyContent="space-between">
              <Box>
                <Box mt={2}>
                  <Typography
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    variant="h5"
                  >
                    {title}
                  </Typography>
                </Box>

                <Box width={!matches ? '60vw' : '20vw'}>
                  <Typography
                    sx={{
                      display: 'block',
                      width: '60%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    variant="body2"
                  >
                    {productInfo}
                  </Typography>
                </Box>
              </Box>

              <Box mr={1} mt={2}>
                <CloseIcon
                  onClick={() => dispatch({ type: 'remove', payload: product })}
                  sx={{ color: '#ff0000', cursor: 'pointer' }}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="space-between" justifyContent="space-between">
              <Box mb={2}>
                <Typography color="textSecondary" variant="h6">
                  {(Math.round(quantity * price * 100) / 100).toFixed(2)} {t('currency')}
                </Typography>
              </Box>

              <Box mb={2}>
                <Box display="flex">
                  <Box
                    ml={1}
                    mr={1}
                    onClick={() => {
                      dispatch({ type: 'add', payload: { ...product, quantity: -1 } });
                      setUpdated(Date.now());
                    }}
                  >
                    <Fab
                      elevation={0}
                      sx={{ width: '2.1rem', height: '2.1rem', backgroundColor: '#fff' }}
                      aria-label="remove"
                    >
                      <RemoveIcon sx={{ width: '1rem', height: '1rem' }} />
                    </Fab>
                  </Box>

                  <Box ml={1} mr={1}>
                    <Typography variant="h5">{quantity}</Typography>
                  </Box>

                  <Box
                    ml={1}
                    mr={1}
                    onClick={() => {
                      dispatch({ type: 'add', payload: { ...product, quantity: 1 } });
                      setUpdated(Date.now());
                    }}
                  >
                    <Fab
                      elevation={0}
                      sx={{ width: '2.1rem', height: '2.1rem', backgroundColor: '#fff' }}
                      aria-label="add"
                    >
                      <AddIcon sx={{ width: '1rem', height: '1rem' }} />
                    </Fab>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
