import { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuPopover from './MenuPopover';
import { useCart } from '../../context/cart/context';

import { useTranslation } from 'react-i18next';

const LANGSRaw = {
  ro: {
    label: 'Romana',
    icon: '/static/icons/ic_flag_ro.svg'
  },
  en: {
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  de: {
    label: 'German',
    icon: '/static/icons/ic_flag_de.svg'
  },
  fr: {
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg'
  },
  it: {
    label: 'Italian',
    icon: '/static/icons/ic_flag_it.svg'
  }
};

export default function LanguagePopover({ languages }) {
  const { t, i18n } = useTranslation();

  const langs = ['ro', ...languages];

  const LANGS = Object.keys(LANGSRaw)
    .filter((key) => langs.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: LANGSRaw[key]
      };
    }, {});

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useCart();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key) => {
    setOpen(false);
    dispatch({ type: 'setLanguage', payload: key });
    i18n.changeLanguage(key);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img
          src={LANGS[state.language]?.icon || '/static/icons/ic_flag_ro.svg'}
          alt={LANGS[state.language]?.label || 'Romana'}
        />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {Object.entries(LANGS).map(([key, value]) => (
            <MenuItem
              key={key}
              selected={key === state.language}
              onClick={() => handleChange(key)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={value.label} src={value.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {value.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
