import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import { Box } from '@mui/material';
import classes from '../App.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { pink } from '@mui/material/colors';
import { useCart } from '../context/cart/context'
import { backendUrl } from '../index'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard({ handleClick, product, restaurant, hasStories, setStory, tableId, restaurantId, restaurantKey }) {
    const [expanded, setExpanded] = React.useState(false);
    const { id, name, description, imgUrl, price, priceDetails, discount, tags, available } = product

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useTranslation();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };  
    const { state, dispatch } = useCart()
    const set = new Set(state.liked)
    const liked = set.has(id)
    function likedPress() {
        if (state.sessionId) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({ tableId, restaurantId, restaurantKey, userId: state.userId, sessionId: state.sessionId, page: window.location.href + "/?userId=" + state.userId + "/card/" + (!liked ? "liked" : "disliked") })
            })
        }
    }
    return (
        <>
            <Box sx={{ width: !matches ? "100vw" : "33vw"}} >
                <Box display="flex">
                    <CardHeader
                        avatar={hasStories ?

                            <Box mr={-1} mb={-1} ml={-1} onClick={() => setStory(true)}
                                sx={{
                                    border: "double 3px transparent",
                                    borderRadius: "3000px",
                                    // backgroundImage: "linear-gradient(white, white), linear-gradient(to right, lightgreen, green)",
                                    backgroundImage: "linear-gradient(white, white), linear-gradient(to right, lightGreen, LawnGreen)",

                                    backgroundOrigin: "border-box",
                                    backgroundClip: "content-box, border-box",
                                    // boxShadow: " 0 0 0 3px #000 inset",

                                }}
                            >
                                <Avatar
                                    style={{
                                        zIndex: 4,
                                        // width: '85px', height: '85px',

                                        border: '1px solid white'

                                    }}
                                    src={restaurant.profileUrlImg}>
                                </Avatar>

                            </Box>

                            : <Box mr={-1} mb={-1} ml={-1}> <Avatar
                                style={{

                                    // width: '100px', height: '100px',

                                    // border: '1px solid white'

                                }}
                                src={restaurant.profileUrlImg}>
                            </Avatar> </Box>
                        }

                        title={<Typography mt={1} sx={{ fontWeight: "bold" }} variant="body1">{restaurant.name}</Typography>}
                    // subheader="September 14, 2016"
                    />
                </Box>
                <Box >
                    <CardMedia
                    onClick={handleClick}
                        component="img"
                        height="300px"
                        image={imgUrl}
                    />
                </Box>
                <Box mr={1} display="flex" flexDirection="row" >

                    <Box mt={"2px"}>
                        <IconButton  onClick={() =>  {likedPress(); dispatch({ type: liked ? 'dislike' : 'like', payload: id });  }} >
                         {liked &&  <FavoriteIcon sx={{color: pink[500]}} fontSize="large"/> }
                           
                          {!liked && <FavoriteBorderIcon color="primary" fontSize="large"/> }
                        </IconButton>
                    </Box>
                    <Box width="80vw">
                    <Typography sx={{
                                fontWeight: "fontWeightBold",
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}  mr={1} mt={2} >
                            {name}, {price} {t('currency')}
                        </Typography>
                        
                    </Box>
                    <Box>
                    
                    </Box>


                </Box>
            </Box>

        </>
    );
}
