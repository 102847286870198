import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';

import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = '4vh';

export default function CategoriesMenu({ options, selected, handleChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleChangeOption = (option) => {
    handleChange(option.hash)();
    setAnchorEl(null);
  };

  return (
    <Box mr={1} ml={-1}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: `calc(${ITEM_HEIGHT} * 12.5)`,
            width: '70%'
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.hash}
            selected={option.hash === selected}
            onClick={() => handleChangeOption(option)}
          >
            <Typography variant="body2">
              {t(option.text)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
