import MapIcon from '@mui/icons-material/Map';
import { IconButton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MapIconButton = () => {
  const history = useHistory();

  const handleMapClick = () => {
    history.push('/restaurants/map');
  };

  return (
    <IconButton onClick={handleMapClick} color="primary">
      <MapIcon style={{ fontSize: 40 }} />
    </IconButton>
  );
};

export default MapIconButton;
