import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import MapIconButton from './AllRestaurantsMap/MapIconButton';
import RestaurantList from './restaurants/RestaurantList';
import SearchAppBar from './SearchAppBar';

import { useTranslation } from 'react-i18next';

const RestaurantsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
  const [geolocationAvailable, setGeolocationAvailable] = useState(null);

  const { t } = useTranslation();

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    marginRight: '10px',
    textDecoration: 'none',
    display: 'inline-block'
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleLocationUpdate = (location) => {
    setUserLocation(location);
  };

  const handleGeolocationStatusChange = (status) => {
    setGeolocationAvailable(status);
  };

  return (
    <>
      <Box display="flex" mt={2} gap={2}>
        <SearchAppBar
          onSearch={handleSearch}
          onLocationUpdate={handleLocationUpdate}
          onGeolocationStatusChange={handleGeolocationStatusChange}
        />
        <Box mt={geolocationAvailable ? 6.5 : 6.5}>
          <MapIconButton />
        </Box>
      </Box>

      <RestaurantList
        searchTerm={searchTerm}
        userLat={userLocation.latitude}
        userLong={userLocation.longitude}
      />

      <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
        <Link to="/acknowledgements/terms-conditions" style={{ textDecoration: 'none' }}>
          <button style={buttonStyle}>{t('termsAndConditions')}</button>
        </Link>

        <Link to="/acknowledgements/privacy-policy" style={{ textDecoration: 'none' }}>
          <button style={buttonStyle}>{t('privacyPolicy')}</button>
        </Link>
      </div>
    </>
  );
};

export default RestaurantsPage;
