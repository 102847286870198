import CloseIcon from '@mui/icons-material/Close';
import { Avatar, ButtonGroup, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import useFetch from 'use-http';
import { backendUrl } from "../index";
import Banner from './Banner';
import Dish from './dish/Dish';
import ScrollableProducts from './scroll/ScrollableProducts';
import ScrollableProductsCategory from './scroll/ScrollableProductsCategory';


import { useTranslation } from 'react-i18next';


import {
    useHistory
} from "react-router-dom";
import AlertDialogSlideCallCheck from './AlertDialogSlideCallCheck';
import AlertDialogSlideCallWaiter from './AlertDialogSlideCallWaiter';
import Loading from './Loading';
import ToggleButtons from './ToggleButtons';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { useCart } from '../context/cart/context';
import { COLOR, INFO, LANGUAGE, MODULE_ORDER, NON_RESTAURANT, NOTIFICATIONS, REPLACE_RECOMANDED_TEXT, useFeature } from '../context/feature/context';

import Fab from '@mui/material/Fab';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import ScrollSpyTabs from './ScrollSpyTabs';

import LanguagePopover from './language/LanguagePopover';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import toast from 'react-hot-toast';
import Stories from 'react-insta-stories';
import useInterval from "../hooks/useInterval";
import NotificationsPopover from './NotificationsPopover';

function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function Section({section, isShareable, restaurantId, ii, restaurantKey, tableId, restaurantSimple, setStory, hasStories, state, isOrderModuleEnabled}) {
    const [mini, setMini] = useState(section.quickCategory)
    const [open, setOpen] = React.useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false)
        }, 8000);
        return () => clearTimeout(timer);
    }, [open]);

    function info() {
        if (state.sessionId) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    tableId,
                    restaurantId,
                    restaurantKey,
                    userId: state.userId,
                    sessionId: state.sessionId,
                    page: window.location.href + "/?userId=" + state.userId + (!mini ? "/minimized" : "/maximazed")
                })
            })
        }
    }

    return (
      <Box key={section.id}>
        <Box
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          mb={0}
          mt={2}
          ml={1}
        >
          <Typography
            sx={{
              fontWeight: "fontWeightBold",
              width: "100%",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            variant="h4"
          >
            {upperCaseFirstLetter(section.name.toLowerCase())}
          </Typography>

          <Tooltip
            placement="top"
            arrow
            open={open && ii === 0}
            title={t('pressToMinimizeCategory')}
          >
            <IconButton
              onClick={() => {
                setMini(!mini);
                setOpen(false);
                info();
              }}
            >
              {!mini && <UnfoldLessIcon color="default" fontSize="large" />}
              {mini && <UnfoldMoreIcon color="default" fontSize="large" />}
            </IconButton>
          </Tooltip>
        </Box>

        {section.specialWarnings && (
          <Box mr={2} ml={1} display='flex' alignItems="center" alignContent="center">
            <InfoOutlined sx={{ color: "#ff0000" }} />
            <Typography sx={{ color: "#ff0000" }} variant='body1' ml="2px" mt="1px">
              {section.specialWarnings}
            </Typography>
          </Box>
        )}

        {mini ? (
          <ScrollableProductsCategory
            tableId={tableId}
            restaurantId={restaurantId}
            restaurantKey={restaurantKey}
            restaurant={restaurantSimple}
            hasOrderModule={isOrderModuleEnabled}
            products={section.products}
          />
        ) : (
          section.products
            .filter(
              (product) =>
                isShareable ||
                !(
                  product.tags.filter((tag) => tag.value === 'DeliveryOnly').length > 0
                )
            )
            .map((dish, i) => (
              <Box key={i}>
                <Dish
                  key={i}
                  hasStories={hasStories}
                  setStory={setStory}
                  restaurant={restaurantSimple}
                  tooltip={i === 0 && ii === 0}
                  tableId={tableId}
                  restaurantId={restaurantId}
                  restaurantKey={restaurantKey}
                  product={dish}
                />
              </Box>
            ))
        )}
      </Box>
    );
}


const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const reducer = (accumulator, currentValue) => accumulator + currentValue;

function getTotalSum(list) {
    return list
        .map(p => p.quantity * p.price)
        .reduce(reducer, 0)
}

const MenuPage = ({ isShareable }) => {
    const [numNotifications, setNumNotifications] = useState(0)
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false)
        }, 4000);
        return () => clearTimeout(timer);
    }, []);

    const { t } = useTranslation();

    const restaurantFetch = useFetch(backendUrl)
    const [story, setStory] = useState(false)
    const { restaurantId, tableId, restaurantKey } = useParams()

    const { state, dispatch } = useCart()
    const { state: featureState, dispatch: featureDispatch } = useFeature()
    const language = state.language

    const [loading, setLoading] = useState(false)
    const [restaurant, setRestaurant] = useState()
    const [restaurantSimple, setRestaurantSimple] = useState()

    const [openWaiter, setOpenWaiter] = React.useState(false);
    const [openCheck, setOpenCheck] = React.useState(false);
    const { state: cart } = useCart()

    const [search, setSeach] = useState(false)
    const [tags, setTags] = useState()

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const history = useHistory()

    useEffect(() => {
        dispatch({ type: 'setUserId' });
        dispatch({ type: 'setSessionId' });

        let restId = restaurantId
        if (!!restaurantKey) {
            restId = restaurantKey
        }

        if (restId !== state.restaurant) {
            dispatch({ type: 'reset' })
            dispatch({ type: 'setRestaurant', payload: restId })
        }

    }, [])

    useEffect(() => {
        if (state.sessionId) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    tableId, restaurantId, restaurantKey,
                    userId: state.userId,
                    sessionId: state.sessionId,
                    page: window.location.href + "/?userId=" + state.userId
                })
            })
        }
    }, [state.sessionId])

    useEffect(() => {
        if (story) {
            fetch(backendUrl + '/aaaa', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    tableId, restaurantId, restaurantKey,
                    userId: state.userId,
                    sessionId: state.sessionId,
                    page: window.location.href + "/?userId=" + state.userId + '/story'
                })
            })
        }
    }, [story])

    async function loadTags() {
        const response = await restaurantFetch.get('/tags')
        if (restaurantFetch.response.ok) {
            setTags(response)
        }
    }
    useEffect(() => {
        loadTags()
    }, [])

    async function loadRestaurant() {
        setLoading(true)
        const response = await restaurantFetch.get(
          '/restaurants/' + 
            (isShareable ? `${restaurantKey}/key` : restaurantId) + 
            (language === 'ro' ? '' : `?lang=${language}`)
        )
        if (restaurantFetch.response.ok) {
            setRestaurant(response)
        }
        setLoading(false)
        window.scrollTo(0, cart.position)
    }

    async function loadRestaurantSimple() {
        const response = await restaurantFetch.get(
          '/restaurants/' + 
            (isShareable ? `${restaurantKey}/key` : restaurantId) + 
            "?simple=true" + 
            (language === 'ro' ? '' : `&lang=${language}`)
        )
        if (restaurantFetch.response.ok) {
            setRestaurantSimple(response)
        }
        window.scrollTo(0, cart.position)
    }

    const isNotificationsModuleEnabled = featureState?.features?.hasOwnProperty(NOTIFICATIONS)

    async function loadInitialNotification() {
        try {
            if (isNotificationsModuleEnabled) {
                const response = await fetch(backendUrl + `/client-notifications/user/${state.userId}`)
                if (response.ok) {
                    const json = await response.json()
                    setNumNotifications(json.notOpenedYet)
                }
            }
        } catch (e) {}
    }

    async function loadNotification() {
        try {
            if (isNotificationsModuleEnabled) {
                const response = await fetch(backendUrl + `/client-notifications/user/${state.userId}`)
                if (response.ok) {
                    const json = await response.json()
                    if (json?.notificationNotReceivedYet?.length > 0) {
                        json?.notificationNotReceivedYet.forEach(n =>
                            toast.success(n.message, { duration: 4000 })
                        )
                    }
                    setNumNotifications(json.notOpenedYet)
                }
            }
        } catch (e) {}
    }

    useInterval(async () => {
        loadNotification()
    }, 5000)

    useEffect(() => {
        loadInitialNotification()
    }, [isNotificationsModuleEnabled])

    function handleInfoOnClick() {
        dispatch({ type: 'setPosition', payload: window.pageYOffset });
        if (isShareable) {
            history.push({ pathname: `/${restaurantKey}/info`, state: restaurantSimple });
        } else {
            history.push({ pathname: `/restaurants/${restaurantId}/tables/${tableId}/info`, state: restaurantSimple });
        }
    }

    useEffect(() => {
        loadRestaurantSimple()
        loadRestaurant()
    }, [state.language])

    useEffect(() => {
        if (restaurantSimple) {
            featureDispatch({ type: "INIT", payload: restaurantSimple.features })
        }
    }, [restaurantSimple])

    useEffect(() => {
        window.scrollTo(0, cart.position)
    }, [])

    if (!restaurantSimple) return <Loading />
    if (!featureState.features) return <Loading />

    let stories = []
    restaurant?.categories.forEach(c => c.products.forEach(p => stories.push(p)))
    stories = stories.filter(p => p.tags.filter(t => t.value === "Story").length > 0).map(product => ({
        content: (props) => (
            <>
                <Avatar
                    sx={{
                        width: '100%',
                        height: '100vh'
                    }}
                    variant="square"
                    src={product.imgUrl}>
                </Avatar>
                <Box
                  alignItems="center"
                  ml={1}
                  mt={3}
                  display="flex"
                  top="0"
                  left={!matches ? "0" : "33vw"}
                  position="fixed"
                >
                    <Avatar
                        sx={{
                            border: '2px solid white',
                            width: !matches ? '12vw' : '4vw',
                            height: !matches ? '12vw' : '4vw'
                        }}
                        src={restaurantSimple.profileUrlImg}>
                    </Avatar>
                    <Typography ml={1} color="white" sx={{ fontWeight: "fontWeightBold" }} variant="button">
                        {restaurantSimple.name}
                    </Typography>
                </Box>
                <Box
                  onClick={() => setStory(false)}
                  zIndex={1000}
                  alignItems="center"
                  mr={1}
                  mt={3}
                  display="flex"
                  top="8px"
                  right={!matches ? "0" : "34vw"}
                  position="fixed"
                >
                    <CloseIcon
                        onClick={() => setStory(false)}
                        fontSize="large"
                        sx={{ color: "#fff" }}
                    />
                </Box>
            </>
        ),
        url: product.imgUrl,
        duration: 3000,
        header: {
            heading: restaurantSimple.name,
            profileImage: restaurantSimple.profileUrlImg,
        },
    }))

    const hasStories = stories.length !== 0
    const color = featureState.features.hasOwnProperty(COLOR) ? featureState.features[COLOR] : '#000'
    const isOrderModuleEnabled = featureState.features.hasOwnProperty(MODULE_ORDER)
    const hasInfo = featureState.features.hasOwnProperty(INFO)
    const languageFeature = featureState.features.hasOwnProperty(LANGUAGE) ? featureState.features[LANGUAGE] : null
    const nonRestaurant = featureState.features.hasOwnProperty(NON_RESTAURANT)
    const replaceRecomandedText = featureState.features.hasOwnProperty(REPLACE_RECOMANDED_TEXT) ? featureState.features[REPLACE_RECOMANDED_TEXT] : 'recommended'

    const tabs = restaurant?.categories
        .filter(c => isShareable || !c.deliveryOnly)
        .map((section, ii) => ({
            text: section.name,
            component: (
                <Section
                  state={state}
                  hasStories={hasStories}
                  setStory={setStory}
                  restaurantSimple={restaurantSimple}
                  tableId={tableId}
                  restaurantId={restaurantId}
                  restaurantKey={restaurantKey}
                  isOrderModuleEnabled={isOrderModuleEnabled}
                  ii={ii}
                  section={section}
                  isShareable={isShareable}
                />
            )
        }))

    const CheamaOspatarul = (
      <Box sx={{ alignItems: 'center', justifyContent: "space-around", display: 'flex' }}>
        <InfoOutlined />
        <Box
          ml={1}
          sx={{
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {t('callWaiter')}
          </Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box mr={1}>
          <Button
            sx={{
              minWidth: !matches ? "29vw" : "10vw",
              borderRadius: 1,
              fontWeight: "fontWeightBold",
              backgroundColor: color
            }}
            onClick={() => {
              setOpenWaiter(true)
            }}
            variant="contained"
          >
            {t('call')}
          </Button>
        </Box>
      </Box>
    )

    const VerificaNota = (
      <Box
        sx={{ mt: "5px", mb: "5px" }}
        alignItems='center'
        justifyContent="space-around"
        onClick={() => {
          history.push({
            pathname: `/restaurants/${restaurantId}/tables/${tableId}/order`,
            state: restaurantSimple
          })
        }}
        display='flex'
      >
        <InfoOutlined />
        <Box ml={1}>
          <Typography variant="h6">
            {t('seeWhatYouOrdered')}
          </Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box mr={1}>
          <Button
            sx={{
              borderRadius: 1,
              minWidth: !matches ? "29vw" : "10vw",
              fontWeight: "fontWeightBold",
              backgroundColor: color
            }}
            variant="contained"
          >
            {t('see')}
          </Button>
        </Box>
      </Box>
    )

    const ComandaTa = (
      <Box
        sx={{ mt: "5px", mb: "5px" }}
        alignItems='center'
        justifyContent="space-around"
        onClick={() => {
          history.push({
            pathname: `/${restaurantKey}/orders/${state.orderId}`,
            state: restaurantSimple
          })
        }}
        display='flex'
      >
        <InfoOutlined />
        <Box ml={1}>
          <Typography variant="h6">
            {t('yourOrder')}
          </Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box mr={1}>
          <Button
            sx={{
              borderRadius: 1,
              minWidth: !matches ? "29vw" : "10vw",
              fontWeight: "fontWeightBold",
              backgroundColor: color
            }}
            variant="contained"
          >
            {t('see')}
          </Button>
        </Box>
      </Box>
    )

    const CereNota = (
      <Box
        sx={{
          mt: "5px",
          mb: "5px",
          alignItems: 'center',
          justifyContent: "space-around",
          display: 'flex'
        }}
      >
        <InfoOutlined />
        <Box
          ml={1}
          sx={{
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {t('askForBill')}
          </Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box mr={1}>
          <Button
            sx={{
              minWidth: !matches ? "29vw" : "10vw",
              borderRadius: 1,
              fontWeight: "fontWeightBold",
              backgroundColor: color
            }}
            onClick={() => {
              setOpenCheck(true)
            }}
            variant="contained"
          >
            {t('ask')}
          </Button>
        </Box>
      </Box>
    )

    const RezervaMasa = (
      <Box
        sx={{ mt: "5px", mb: "5px", display: 'flex', alignItems: 'center', justifyContent: "space-around" }}
      >
        <InfoOutlined />
        <Box
          ml={1}
          sx={{
            width: "100%",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {t('quicklyReserveTable')}
          </Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box mr={1}>
          <Button
            sx={{
              minWidth: !matches ? "29vw" : "10vw",
              borderRadius: 1,
              fontWeight: "fontWeightBold",
              backgroundColor: color
            }}
            onClick={() => {
              setOpenCheck(true)
            }}
            variant="contained"
          >
            {t('reserve')}
          </Button>
        </Box>
      </Box>
    )

    const between = (
      <Box sx={{ display: 'flex', flexDirection: "column" }}>
        <Box ml={1} sx={{ mt: "45px", mb: "10px" }}>
          <Typography fontWeight="bold" variant="h3">
            {restaurantSimple.name}
          </Typography>
          <Typography ml="3px" variant="body2">
            {restaurantSimple.description}
          </Typography>
        </Box>

        <Box sx={{ marginLeft: 1 }}>
          {!isShareable && CheamaOspatarul}
          {!isShareable && isOrderModuleEnabled && VerificaNota}
          {!isShareable && CereNota}
          {nonRestaurant && isShareable && state.orderId !== '' && ComandaTa}
        </Box>

        <Box>
          {nonRestaurant && isShareable && <Box mb={3}><ToggleButtons /></Box>}
        </Box>

        <Box mb={4} ml={1}>
          <ScrollableProducts
            tableId={tableId}
            restaurantId={restaurantId}
            restaurantKey={restaurantKey}
            hasOrderModule={isOrderModuleEnabled}
            restaurant={restaurant}
            replaceRecomandedText={replaceRecomandedText}
          />
        </Box>

        <Box mb={2}>
          <Banner></Banner>
        </Box>
      </Box>
    )

    return (
        <>
            {story ? (
                <Stories
                  width={!matches ? "100vw" : "33vw"}
                  height="100vh"
                  onStoryEnd={(s, st) => console.log('story ended', s, st)}
                  onAllStoriesEnd={() => setStory(false)}
                  stories={stories}
                />
            ) : (
                <>
                    <div />
                    <AlertDialogSlideCallWaiter
                      tableId={tableId}
                      restaurantId={restaurantId}
                      color={color}
                      open={openWaiter}
                      setOpen={setOpenWaiter}
                    />
                    <AlertDialogSlideCallCheck
                      tableId={tableId}
                      restaurantId={restaurantId}
                      color={color}
                      open={openCheck}
                      setOpen={setOpenCheck}
                    />

                    <Avatar
                        sx={{
                            width: '100%',
                            height: '200px'
                        }}
                        variant="square"
                        src={restaurantSimple.coverUrlImg}
                    />

                    {hasInfo && (
                        <Fab
                            onClick={handleInfoOnClick}
                            size="small"
                            aria-label="add"
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: !matches ? '10px' : 'calc(33vw + 10px)',
                                zIndex: 1,
                                backgroundColor: "#fafafa"
                            }}
                        >
                            <InfoOutlined />
                        </Fab>
                    )}

                    {languageFeature && (
                      <Fab
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '10px',
                          right: !matches ? '60px' : 'calc(33vw + 60px)',
                          zIndex: 1,
                          backgroundColor: "#fafafa"
                        }}
                        aria-label="add"
                      >
                        <LanguagePopover languages={languageFeature.split(',')} />
                      </Fab>
                    )}

                    {numNotifications > 0 && (
                      <Fab
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: '10px',
                          right: !matches ? '110px' : 'calc(33vw + 110px)',
                          zIndex: 1,
                          backgroundColor: "#fafafa"
                        }}
                        aria-label="add"
                      >
                        <NotificationsPopover
                          restaurantId={restaurantId}
                          userId={state.userId}
                          numNotifications={numNotifications}
                        />
                      </Fab>
                    )}

                    <Box mt={2}>
                        <ScrollSpyTabs
                          restaurantId={restaurantId}
                          restaurantKey={restaurantKey}
                          tableId={tableId}
                          tabsInScroll={tabs}
                          between={between}
                          loading={loading}
                        />
                    </Box>

                    <Box ml={1} sx={{ position: "absolute", top: "150px" }}>
                        {hasStories ? (
                            <Tooltip arrow open={open} title={t('restaurantStory')}>
                                <Box
                                  className="conic-gradient"
                                  onClick={() => setStory(true)}
                                  p="2px"
                                >
                                    <Avatar
                                        style={{
                                            zIndex: 4,
                                            width: '85px',
                                            height: '85px',
                                            border: '3px solid white'
                                        }}
                                        src={restaurantSimple.profileUrlImg}
                                    />
                                </Box>
                            </Tooltip>
                        ) : (
                            <Avatar
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    border: '5px solid white'
                                }}
                                src={restaurantSimple.profileUrlImg}
                            />
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box>
                            <div style={{ margin: 10 }}>
                                <ButtonGroup color="secondary"></ButtonGroup>
                            </div>
                        </Box>
                    </Box>

                    {isOrderModuleEnabled && (
                      <Box>
                        {cart.items.length > 0 && (
                          <Box
                            sx={{
                              fontWeight: "fontWeightBold",
                              position: 'fixed',
                              bottom: 20,
                              left: !matches ? 10 : 'calc(10px + 33vw)',
                              right: !matches ? 10 : 'calc(10px + 33vw)',
                              zIndex: 11
                            }}
                            elevation={3}
                          >
                            <Button
                              onClick={() =>
                                history.push({
                                  pathname:
                                    (restaurantKey
                                      ? `/${restaurantKey}`
                                      : `/restaurants/${restaurantId}/tables/${tableId}`) + '/cart',
                                  state: { restaurant: restaurant, features: featureState.features }
                                })
                              }
                              sx={{ fontWeight: "fontWeightBold", minHeight: '60px' }}
                              size="large"
                              variant="contained"
                              fullWidth
                            >
                              {t('seeCurrentOrder')} -{' '}
                              {(Math.round(getTotalSum(cart.items) * 100) / 100).toFixed(2)} RON
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}
                </>
            )}
        </>
    )
}
export default MenuPage
