import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box } from '@mui/material';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import Tooltip from '@mui/material/Tooltip';
import { useFeature, MODULE_ORDER, COLOR } from '../../context/feature/context';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';
import { useCart } from '../../context/cart/context';
import { useHistory } from 'react-router-dom';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Tag from '../Tag';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    '&::after': {
      position: 'absolute',
      top: '101px',
      left: '101px',
      width: '100%',
      height: '100%'
    }
  }
}));

export default function DishNoImage({
  product,
  restaurantId,
  restaurantKey,
  tableId
}) {
  const { state, dispatch } = useCart();
  const history = useHistory();
  const { state: featureState } = useFeature();
  const { t } = useTranslation();

  const color = featureState.features.hasOwnProperty(COLOR)
    ? featureState.features[COLOR]
    : '#000';

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, [open]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (!product) {
    return null; 
  }

  const {
    id,
    name,
    description,
    imgUrl,
    price,
    priceDetails,
    discount,
    tags=[],
    available
  } = product;

  const newTag = tags.map((tag) => tag.value).includes('New');
  const popularTag = tags.map((tag) => tag.value).includes('Popular');
  const deliveryOnlyTag = tags.map((tag) => tag.value).includes('DeliveryOnly');

  const isAvailable = available || available === undefined;
  const hasOrderModule = featureState.features.hasOwnProperty(MODULE_ORDER);

  function handleOnClick() {
    if (isAvailable) {
      dispatch({ type: 'setPosition', payload: window.pageYOffset });
      history.push({
        pathname:
          (restaurantKey
            ? `/${restaurantKey}`
            : `/restaurants/${restaurantId}/tables/${tableId}`) + `/dish-details/${id}`,
        state: { product, hasOrderModule }
      });
    }
  }

  return (
    <Box
      sx={{
        borderRadius: 0
      }}
      onClick={handleOnClick}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 0,
          filter: `opacity(${isAvailable ? 100 : 50}%)`
        }}
      >
        <Box ml={1} mt={2} mr={2} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" width="90%">
            <Box display="flex">
              <Typography
                sx={{
                  fontWeight: 'fontWeightBold',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                variant="h6"
              >
                {name}
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              mt={0}
            >
              <Typography variant="subtitle" color="textSecondary">
                {description}
              </Typography>
            </Box>

            <Box display="flex">
              <Box mr={1}>
                {(!discount || discount === 0) && (
                  <Typography sx={{ fontWeight: 'fontWeightBold' }} variant="h6">
                    {price} {t('currency')}
                  </Typography>
                )}

                {!!discount && discount !== 0 && (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      mr={1}
                      sx={{
                        color: '#bbbbbb',
                        fontWeight: 'fontWeightBold',
                        textDecorationLine: 'line-through'
                      }}
                      variant="h6"
                    >
                      {price} {t('currency')}
                    </Typography>

                    <Typography mr={1} sx={{ fontWeight: 'fontWeightBold', color: 'red' }} variant="h6">
                      {((100 - discount) * price) / 100} {t('currency')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box mt={0} mb={0}>
              {tags
                .filter(
                  (tag) =>
                    ![
                      'New',
                      'Recomandate',
                      'Story',
                      'Popular',
                      'BigPost',
                      'DeliveryOnly'
                    ].includes(tag.value)
                )
                .map((tag) => {
                  if (tag) {
                    return <Tag key={tag.id} tag={tag.value} color={tag.color} />;
                  }
                  return null;
                })}

              {popularTag && (
                <Chip
                  size="small"
                  variant="outlined"
                  clickable={true}
                  style={{
                    backgroundColor: green[700],
                    border: '0px',
                    marginRight: '5px',
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '10px'
                  }}
                  key="tag-popular"
                  label={t('popular')}
                />
              )}
              {deliveryOnlyTag && (
                <Chip
                  size="small"
                  variant="outlined"
                  clickable={true}
                  style={{
                    backgroundColor: blue[600],
                    border: '0px',
                    marginRight: '5px',
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '10px'
                  }}
                  key="tag-deliveryOnly"
                  label={t('deliveryOnly')}
                />
              )}
              {newTag && (
                <Chip
                  size="small"
                  variant="outlined"
                  clickable={true}
                  style={{
                    backgroundColor: red[700],
                    border: '0px',
                    marginRight: '5px',
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '10px'
                  }}
                  key="tag-new"
                  label={t('new')}
                />
              )}
            </Box>

            {!isAvailable && (
              <Chip
                size="small"
                variant="outlined"
                clickable={true}
                style={{
                  backgroundColor: '#E8E8E8',
                  border: '0px',
                  marginRight: '5px',
                  fontWeight: 'bold',
                  color: '#000',
                  fontSize: '10px'
                }}
                key="unavailable"
                label={t('unavailable')}
              />
            )}
          </Box>

          <Box ml={2} mt={2} mb={2}>
            {hasOrderModule && (
              <Fab
                disabled={!isAvailable}
                size="small"
                sx={{
                  '&:hover': { backgroundColor: '#fff' },
                  backgroundColor: '#fff',
                  color,
                  zIndex: 0,
                  position: 'relative',
                  top: '-5px',
                  right: '10px'
                }}
                color="secondary"
                aria-label="add"
              >
                <AddIcon />
              </Fab>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
