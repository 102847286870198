import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { backendUrl } from '../index';
import Dish from './dish/Dish';
import Box from '@mui/material/Box';
import { Avatar, Typography, Paper, IconButton, ButtonGroup } from '@mui/material';
import useFetch from 'use-http';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useHistory } from 'react-router-dom';
import { useCart } from '../context/cart/context';
import Map from './Map/Map';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RestaurantInfoDetails from './RestaurantInfoDetails';

import { useTranslation } from 'react-i18next';

const RestaurantInfoPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { restaurantId, restaurantKey, tableId } = useParams();
  const [restaurantSimple, setRestaurantSimple] = useState();
  const { state, dispatch } = useCart();

  const { t } = useTranslation();

  useEffect(() => {
    if (state.sessionId) {
      fetch(backendUrl + '/aaaa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tableId,
          restaurantId,
          restaurantKey,
          userId: state.userId,
          sessionId: state.sessionId,
          page: window.location.href + '/?userId=' + state.userId
        })
      });
    }
  }, [state.sessionId]);

  const language = state.language;

  async function loadRestaurantSimple() {
    const path = restaurantId ? restaurantId : `${restaurantKey}/key`;
    const url =
      backendUrl +
      '/restaurants/' +
      path +
      '?simple=true' +
      (language === 'ro' ? '' : `&lang=${language}`);

    const response = await fetch(url);
    if (response.ok) {
      const json = await response.json();
      setRestaurantSimple(json);
    }
    window.scrollTo(0, state.position);
  }

  const { state: cart } = useCart();

  useEffect(() => {
    loadRestaurantSimple();
  }, []);

  useEffect(() => {
    window.scrollTo(0, cart.position);
  }, []);

  const history = useHistory();

  if (!restaurantSimple)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
        <Typography ml={2} variant="h6">
          {t('loadingMessage')}
        </Typography>
      </Box>
    );

  const between = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ ml: 3, mt: '20px' }}>
        <Typography fontWeight="bold" variant="h4">
          {restaurantSimple.name}
        </Typography>
        <Typography ml="3px" variant="body2">
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Fab
        size="small"
        onClick={() => history.goBack()}
        sx={{
          position: 'absolute',
          top: '10px',
          left: !matches ? '10px' : 'calc(10px + 33vw)',
          zIndex: 1,
          backgroundColor: '#fafafa'
        }}
        aria-label="back"
      >
        <ArrowBackIcon />
      </Fab>

      <Map latR={restaurantSimple.latitude} lngR={restaurantSimple.longitude} />

      <Box mt={0}>{between}</Box>

      <Box ml={1} sx={{ position: 'absolute', top: '210px' }}>
       
      </Box>

      <Box mt={1}>
        <RestaurantInfoDetails
          ssid={restaurantSimple.ssid}
          wifiPassword={restaurantSimple.wifiPassword}
          address={restaurantSimple.address}
          program={restaurantSimple.program}
          email={restaurantSimple.email}
          phone={restaurantSimple.phone}
        />
      </Box>
    </>
  );
};

export default RestaurantInfoPage;
