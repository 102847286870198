import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { themeOptions } from './theme'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {CartProvider } from './context/cart/context'
import {FeatureProvider } from './context/feature/context'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, CircularProgress } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import  'moment/locale/ro'
export const backendUrl = "https://reach-main.herokuapp.com";
// export const backendUrl = "https://reach-main-mtglug2i2a-ez.a.run.app"
// export const backendUrl = "http://localhost:8080";
const theme = createTheme(themeOptions);



i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "ro",
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    }
  });


ReactDOM.render(
  <Suspense fallback={
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      >
      <CircularProgress size={70} />
  </Box>
  }>
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <FeatureProvider>
        <CartProvider>
          <LocalizationProvider locale={"ro"} dateAdapter={DateAdapter}>
          <App />
          </LocalizationProvider>
        </CartProvider>
      </FeatureProvider>
    </ThemeProvider>
  </React.StrictMode>
  </Suspense>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
