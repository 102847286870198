import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import AllRestaurantsMarker from './AllRestaurantsMarker';

export default function MapForAll({ latR = 44.4268, lngR = 26.1025, restaurants = [] }) {
    const defaultProps = {
        center: { lat: parseFloat(latR) || 44.4268, lng: parseFloat(lngR) || 26.1025 },
        zoom: 12,
    };

    const [mapInstance, setMapInstance] = useState(null);
    const [clusters, setClusters] = useState([]);
    const [activePin, setActivePin] = useState(null);
    const [zoom, setZoom] = useState(defaultProps.zoom);

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
                Math.cos((lat2 * Math.PI) / 180) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const getClusterDistanceByZoom = (zoomLevel) => {
        if (zoomLevel <= 4) return Infinity;
        if (zoomLevel >= 20) return 0;
        if (zoomLevel === 19) return 0.01;
        if (zoomLevel === 18) return 0.05;
        if (zoomLevel === 17) return 0.08;
        if (zoomLevel === 16) return 0.1;
        if (zoomLevel === 15) return 0.2;
        if (zoomLevel === 14) return 0.35;
        if (zoomLevel === 13) return 0.5;
        if (zoomLevel === 12) return 1.4;
        if (zoomLevel === 11) return 20.8;
        if (zoomLevel === 10) return 35.0;
        if (zoomLevel === 9) return 30.2;
        if (zoomLevel === 8) return 40.5;
        if (zoomLevel === 7) return 50.0;
        if (zoomLevel === 6) return 95.5;
        if (zoomLevel === 5) return 200.0;
        return 0.5;
    };

    useEffect(() => {
        if (mapInstance && restaurants.length > 0) {
            const thresholdDistance = getClusterDistanceByZoom(zoom);

            if (zoom <= 4) {
                setClusters([restaurants]);
                return;
            }

            if (zoom >= 20) {
                setClusters(restaurants.map(restaurant => [restaurant]));
                return;
            }

            let clusteredMarkers = [];
            let usedIndexes = new Set();

            restaurants.forEach((restaurant, index) => {
                if (usedIndexes.has(index)) return;

                let cluster = [restaurant];
                usedIndexes.add(index);

                restaurants.forEach((otherRestaurant, otherIndex) => {
                    if (index !== otherIndex && !usedIndexes.has(otherIndex)) {
                        const distance = haversineDistance(
                            restaurant.latitude,
                            restaurant.longitude,
                            otherRestaurant.latitude,
                            otherRestaurant.longitude
                        );

                        if (distance < thresholdDistance) {
                            cluster.push(otherRestaurant);
                            usedIndexes.add(otherIndex);
                        }
                    }
                });

                clusteredMarkers.push(cluster);
            });

            setClusters(clusteredMarkers);
        }
    }, [mapInstance, restaurants, zoom]);

    const handlePinClick = (id, cluster) => {
        if (cluster && cluster.length > 1 && mapInstance) {
            const newZoom = Math.min(zoom + 2, 20);
            const clusterCenter = cluster[0];
            
            mapInstance.setZoom(newZoom);
            mapInstance.panTo(new window.google.maps.LatLng(clusterCenter.latitude, clusterCenter.longitude));
            setActivePin(null);
        } else {
            setActivePin(id === activePin ? null : id);
        }
    };

    const handleZoomChange = (newZoom) => {
        if (Math.abs(zoom - newZoom) >= 1) {
            setZoom(newZoom); 
        }
    };

    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw' }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onGoogleApiLoaded={({ map }) => {
                    setMapInstance(map);
                    const styles = [
                        {
                            featureType: "poi",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "transit",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "administrative.land_parcel",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "administrative.neighborhood",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                    ];
            
                    map.setOptions({ styles });
                    map.addListener('zoom_changed', () => handleZoomChange(map.getZoom()));
                }}
                yesIWantToUseGoogleMapApiInternals
                onZoomAnimationEnd={handleZoomChange}
            >
                {clusters.map((cluster, index) => {
                    if (cluster.length === 1) {
                        const marker = cluster[0];
                        return (
                            <AllRestaurantsMarker
                                key={marker.id}
                                lat={marker.latitude}
                                lng={marker.longitude}
                                restaurant={marker}
                                isCluster={false}
                                isActive={activePin === marker.id}
                                onClick={() => handlePinClick(marker.id)}
                            />
                        );
                    } else {
                        const marker = cluster[0];
                        return (
                            <AllRestaurantsMarker
                                key={index} 
                                lat={marker.latitude}
                                lng={marker.longitude}
                                restaurant={marker}
                                isCluster={true}
                                clusterCount={cluster.length}
                                isActive={false}
                                onClick={() => handlePinClick(null, cluster)}
                            />
                        );
                    }
                })}
            </GoogleMapReact>
        </div>
    );
}
