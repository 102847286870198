import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Avatar, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useHistory } from 'react-router-dom';
import Tag from '../Tag';
import { calculateDistance, calculateEstimatedTime } from './distanceUtils';
import { useTranslation } from 'react-i18next';

export default function Restaurant({
  restaurant,
  userLat,
  userLong,
  widthBox = '100vw',
  widthAvatar = '96vw',
  height = '22vh',
  hasTags = true,
  showUnknownDistance = true,
}) {
  const { imageUrl, urlKey, name, latitude: restaurantLat, longitude: restaurantLong } = restaurant;

  const { t } = useTranslation();
  const history = useHistory();
  const [distance, setDistance] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);

  useEffect(() => {
    if (userLat && userLong && restaurantLat && restaurantLong) {
      const dist = calculateDistance(userLat, userLong, restaurantLat, restaurantLong);
      setDistance(dist ? dist.toFixed(2) : null);

      const estimatedTimeStr = calculateEstimatedTime(dist);
      setEstimatedTime(estimatedTimeStr);
    }
  }, [userLat, userLong, restaurantLat, restaurantLong]);

  const handleOnClick = () => {
    history.push(urlKey);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: widthBox }}
    >
      <Box>
        <Avatar
          onClick={handleOnClick}
          sx={{
            borderRadius: 1,
            width: widthAvatar,
            height: height,
            cursor: 'pointer',
          }}
          src={imageUrl}
        />
      </Box>

      <Box mt={1} sx={{ width: widthAvatar }}>
        <Box alignSelf="flex-start">
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            {name}
          </Typography>

          <Box mt={0.5} display="flex" alignItems="center" flexWrap="wrap">
            {hasTags &&
              [{ value: '🍕', color: 0 }, { value: '🍗', color: 1 }].map((tag, index) => (
                <Tag key={index} tag={tag.value} color={tag.color} />
              ))}

            {distance ? (
              <Typography variant="body1" ml={1} display="flex" alignItems="center">
                {distance} {t('km')}
                {estimatedTime !== null && (
                  <>
                    {' '}
                    • <AccessTimeIcon fontSize="small" sx={{ ml: 0.5, mr: 0.5 }} />
                    {estimatedTime}
                  </>
                )}
              </Typography>
            ) : (
              showUnknownDistance && (
                <Typography variant="body2" color="textSecondary" ml={1}>
                  {t('unknownDistance')}
                </Typography>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
