import React, { useEffect, useState } from "react";
import { backendUrl } from "../../index";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MapForAll from "./MapForAll";
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

const RestaurantMap = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const { t } = useTranslation();

    const loadRestaurants = async () => {
        try {
            const response = await fetch(`${backendUrl}/restaurants?simple=true`);
            if (response.ok) {
                const data = await response.json();
    
                const filteredRestaurants = data
                    .filter(restaurant =>
                        restaurant.latitude && restaurant.longitude &&
                        !(restaurant.latitude === 0 && restaurant.longitude === 0)
                    )
                    .map(restaurant => ({
                        ...restaurant,
                        imageUrl: restaurant.coverUrlImg || 'https://via.placeholder.com/200x100',
                        description: restaurant.description || t('fallbackDescription'),
                        urlKey: `/${restaurant.urlKey || restaurant.id}`,
                    }));
    
                setRestaurants(filteredRestaurants);
            } else {
                console.error("Failed to fetch restaurants");
            }
        } catch (error) {
            console.error("Error fetching restaurants: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadRestaurants();
    }, []);

    const handleBackClick = () => {
        history.push('/restaurants');
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box position="relative" width="100%" height="100vh">
            <IconButton
                onClick={handleBackClick}
                sx={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    zIndex: 1000,
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '50%',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        backgroundColor: '#f0f0f0'
                    },
                    width: '45px',
                    height: '45px',
                }}
            >
                <ArrowBackIcon />
            </IconButton>
            <MapForAll
                latR={restaurants.length > 0 ? restaurants[0].latitude : 44.4268}
                lngR={restaurants.length > 0 ? restaurants[0].longitude : 26.1025}
                restaurants={restaurants}
            />
        </Box>
    );
};

export default RestaurantMap;
