import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

import { backendUrl } from '../index';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, color, tableId, restaurantId }) {
  const [load, setLoad] = useState(false);

  const { t } = useTranslation();

  const handleClose = async () => {
    setLoad(true);
    await fetch(backendUrl + '/notifications', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        tableId,
        restaurantId,
        message: t('youWereCalledToTheTable')
      })
    });
    setLoad(false);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography sx={{ fontWeight: 'fontWeightBold' }}>
          {t('areYouSureWantToCallWaiter')}
        </Typography>
        <Typography>{t('theWaiterWillArriveSoon')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            '&:hover': { backgroundColor: '#808080' },
            borderRadius: 0,
            backgroundColor: '#fff',
            color: '#000'
          }}
          disabled={load}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          {t('cancel')}
        </Button>

        <Button
          sx={{
            fontWeight: 'fontWeightBold',
            borderRadius: 0,
            backgroundColor: color
          }}
          disabled={load}
          variant="contained"
          onClick={handleClose}
        >
          {!load && t('ok')}
          {load && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress sx={{ color: '#fff', fontWeight: 'fontWeightBold' }} size={20} />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
