import { Avatar, Typography, Paper, IconButton, ButtonGroup, Box } from '@mui/material';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import Link from '@mui/material/Link';

import React from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyTextButton from './CopyTextButton';


import { useTranslation } from 'react-i18next';

export default function RestaurantInfoDetails({
  ssid,
  wifiPassword,
  address,
  program,
  email,
  phone
}) {
  const { t } = useTranslation();

  return (
    <Box ml={1} mr={1}>
      {ssid && (
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box display="flex" alignItems="center">
              <Box mt="5px" mr={1}>
                <WifiIcon />
              </Box>
              <Typography variant="h5">{t('wifi')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Typography>
              {t('name')}: {ssid}
            </Typography>

            {wifiPassword && (
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box>
                  <Typography>
                    {t('password')}: {wifiPassword}
                  </Typography>
                </Box>
                <Box mt={-1}>
                  <CopyTextButton text={wifiPassword} />
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {address && (
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Box display="flex" alignItems="center">
              <Box mt="4px" mr={1}>
                <LocationOnOutlinedIcon />
              </Box>
              <Typography variant="h5">{t('address')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography>
                  <Link
                    sx={{ color: 'blue' }}
                    underline="none"
                    href={`https://www.google.com/maps/place/${address}`}
                  >
                    {address}
                  </Link>
                </Typography>
              </Box>
              <Box mt={-1}>
                <CopyTextButton text={address} />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {program && (
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Box display="flex" alignItems="center">
              <Box mt="5px" mr={1}>
                <WatchLaterOutlinedIcon />
              </Box>
              <Typography variant="h5">{t('schedulee')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>
              <Typography>{program}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {(email || phone) && (
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Box display="flex" alignItems="center">
              <Box mt="4px" mr={1}>
                <PhoneIphoneOutlinedIcon />
              </Box>
              <Typography variant="h5">{t('contact')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {email && (
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box>
                  <Typography>
                    {t('email')}: {email}
                  </Typography>
                </Box>
                <Box mt={-1}>
                  <CopyTextButton text={email} />
                </Box>
              </Box>
            )}

            {phone && (
              <Typography>
                {t('phone')}: <a href={`tel:${phone}`}>{phone}</a>
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}
