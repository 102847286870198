import './App.css';

import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import './theme/variables.css';

import { Toaster } from 'react-hot-toast';
import RestaurantMap from './components/AllRestaurantsMap/RestaurantMap';
import CartDetailsPage from './components/CartDetailsPage';
import CollectInfoPage from './components/CollectInfoPage';
import DishDetails from './components/dish/DishDetails';
import MenuPage from './components/MenuPage';
import NotFound from './components/NotFound';
import RestaurantInfoPage from './components/RestaurantInfoPage';
import RestaurantsPage from './components/RestaurantsPage';
import TableOrderDetailsPage from './components/TableOrderDetailsPage';

import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsConditionsPage from './components/TermsConditionsPage';

function App() {
  return (
    <>
      <CssBaseline />
      <Toaster/>
      <Grid container>
        <Grid item md={4} lg={4} xl={4}>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Router>
            <Switch>
              <Redirect exact from='/' to='/restaurants'/>
              <Route path="/restaurants/map" render={() => <RestaurantMap />} />
              {/* <Route  path="/dish-details/:id" render={() => <DishDetails />} /> */}
              {/* <Route  path="/restaurant-details/:restaurantId" render={() => <RestaurantInfoPage />} /> */}
             
              {/* <Route  path="/cart" render={() => <CartDetailsPage />}  exact={true} /> */}
              {/* <Route  path="/add-info" render={() => <CollectInfoPage />}  exact={true} /> */}
              <Route path="/acknowledgements/terms-conditions" component={TermsConditionsPage} exact />
              <Route path="/acknowledgements/privacy-policy" component={PrivacyPolicyPage} exact />

              <Route  path="/restaurants/:restaurantId/tables/:tableId/cart" render={() => <CartDetailsPage />} exact={true} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/order" render={() => <TableOrderDetailsPage />} exact={true} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/dish-details/:id" render={() => <DishDetails />} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId/info" render={() => <RestaurantInfoPage />} />
              <Route  path="/restaurants/:restaurantId/tables/:tableId" render={() => <MenuPage />} />

              <Route  path="/restaurants" render={() => <RestaurantsPage />} />

              <Route path="/:restaurantKey/dish-details/:id" render={() => <DishDetails />} />
              <Route path="/:restaurantKey/info" render={() => <RestaurantInfoPage />} exact={true} />
              <Route path="/:restaurantKey/cart" render={() => <CartDetailsPage />} exact={true} />
              <Route path="/:restaurantKey/add-info" render={() => <CollectInfoPage />} exact={true} />
              <Route path="/:restaurantKey/orders/:orderId" render={() => <TableOrderDetailsPage />} exact={true} />
              <Route path="/:restaurantKey" render={() => <MenuPage isShareable/>} />

              <Route component={NotFound} />
            </Switch>
          </Router>
        </Grid>
        <Grid item lg={4} xl={4}>
          {/* <Box  sx={{backgroundColor: "#fafafa", width:"100%", height:"100%"}}></Box> */}
        </Grid>
      </Grid>
    </>
  );
}

export default App;
