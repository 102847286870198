import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { set, sub, formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';

// material
import { Popover } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import SimpleBarReact from 'simplebar-react';
import Loading from './Loading'
import { backendUrl } from '../index'
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: 0,
    title: 'Your order is placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
  },
  {
    id: 1,
    title: 'Your order is placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
  },
  {
    id: 2,
    title: "tttt",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
  },
  {
    id: 3,
    title: 'You have new message',
    avatar: null,
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
  },
  {
    id: 4,
    title: 'You have new mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
  },
  {
    id: 5,
    title: 'Delivery processing',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
  }
];

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
    </Typography>
  );

  return { title };
}

function NotificationItem({ notification }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      to="#"
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px'
      }}
    >
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover({ restaurantId, userId, numNotifications }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState();
  const totalUnRead = numNotifications;

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchNotifications() {
      if (open) {
        const res = await fetch(backendUrl + `/client-notifications/user/${userId}/not-opened`);
        const json = await res.json();
        setNotifications(
          json.map((n) => ({
            id: n.id,
            title: n.message,
            createdAt: sub(new Date(), new Date(n.timestamp))
          }))
        );
      }
    }

    fetchNotifications();
  }, [open, userId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false
      }))
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color="primary"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge
          badgeContent={totalUnRead}
          sx={{
            "& .MuiBadge-badge": {
              color: "white",
              fontWeight: "bold",
              backgroundColor: "red"
            }
          }}
        >
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{t('notificationsTitle')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('youHaveUnreadMessages', { count: totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={t('markAllAsRead')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        {!notifications && <Loading minHeight="10vh" />}

        {notifications && (
          <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ fontWeight: 'bold', py: 1, px: 2.5, typography: 'overline' }}
                >
                  {t('notificationsNewSection')}
                </ListSubheader>
              }
            >
              {notifications?.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>
          </Scrollbar>
        )}

        <Divider />

      </MenuPopover>
    </>
  );
}

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`
  }
}));

function MenuPopover({ children, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.5,
          overflow: 'inherit',
          border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
          width: 200,
          ...sx
        }
      }}
      {...other}
    >
      <ArrowStyle className="arrow" />

      {children}
    </Popover>
  );
}

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
});

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-mask': {
    zIndex: 'inherit'
  }
}));

function Scrollbar({ children, sx, ...other }) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
