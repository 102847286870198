import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { backendUrl } from '../../index';
import Loading from '../Loading';
import ScrollableMenu from '../scroll/ScrollableMenu';
import ScrollableTags from '../scroll/ScrollableTags';
import Restaurant from './Restaurant';
import { calculateDistance } from './distanceUtils';

import { useTranslation } from 'react-i18next';

export default function RestaurantList({ searchTerm, userLat, userLong }) {
  const { t } = useTranslation();

  const [originalRestaurants, setOriginalRestaurants] = useState(null);
  const [restaurants, setRestaurants] = useState(null);
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const loadRestaurants = async () => {
    try {
      const response = await fetch(`${backendUrl}/restaurants?simple=true`);
      if (response.ok) {
        const data = await response.json();

        const filteredRestaurants = data
          .filter(
            (restaurant) =>
              restaurant.latitude &&
              restaurant.longitude &&
              !(restaurant.latitude === 0 && restaurant.longitude === 0) &&
              restaurant.active
          )
          .map((restaurant) => ({
            ...restaurant,
            imageUrl: restaurant.coverUrlImg || 'https://via.placeholder.com/200x100',
            description: restaurant.description || t('restaurantDescription'),
            urlKey: restaurant.urlKey || `/restaurant/${restaurant.id}`,
          }));

        setOriginalRestaurants(filteredRestaurants);

        if (userLat && userLong) {
          const sorted = filteredRestaurants
            .map((rest) => {
              const dist = calculateDistance(
                userLat,
                userLong,
                rest.latitude,
                rest.longitude
              );
              return { ...rest, distance: dist };
            })
            .sort((a, b) => a.distance - b.distance);

          setRestaurants(sorted);
        } else {
          setRestaurants(filteredRestaurants);
        }
      } else {
        console.error('Eroare la primirea restaurantelor:', response.status);
      }
    } catch (error) {
      console.error('Eroare la cererea API:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadTags = async () => {
    try {
      const response = await fetch(`${backendUrl}/tags`);
      if (response.ok) {
        const data = await response.json();
        setTags(data);
      } else {
        console.error('Eroare la primirea tag-urilor:', response.status);
      }
    } catch (error) {
      console.error('Eroare la cererea API:', error);
    }
  };

  useEffect(() => {
    loadRestaurants();
    loadTags();
  }, []);

  useEffect(() => {
    if (originalRestaurants && userLat && userLong) {
      const sorted = originalRestaurants
        .map((rest) => {
          const dist = calculateDistance(
            userLat,
            userLong,
            rest.latitude,
            rest.longitude
          );
          return { ...rest, distance: dist };
        })
        .sort((a, b) => a.distance - b.distance);
      setRestaurants(sorted);
    }
  }, [userLat, userLong, originalRestaurants]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (loading) return <Loading />;

  if (!restaurants || restaurants.length === 0) {
    return <Typography variant="h6">{t('noRestaurantsFound')}</Typography>;
  }

  if (!tags) return null;

  const displayedRestaurants = searchTerm
    ? restaurants.filter((restaurant) =>
        restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : restaurants;

  const totalPages = Math.ceil(displayedRestaurants.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentRestaurants = displayedRestaurants.slice(startIndex, endIndex);

  const restaurantsList = currentRestaurants.map((restaurant) => (
    <Box key={restaurant.id} mt={4}>
      <Restaurant
        restaurant={restaurant}
        userLat={userLat}
        userLong={userLong}
        widthAvatar={!matches ? '96vw' : '32vw'}
        widthBox={!matches ? '89vw' : '32vw'}
      />
    </Box>
  ));

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <Box mx={3} mb={4}>
      <Box mt={4} mb={2}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          {t('recommendations')}
        </Typography>
        <Typography sx={{ marginLeft: '3px', fontWeight: 'bold' }} variant="subtitle2">
          {t('byTomisHotel')}
        </Typography>
      </Box>

      <Box mt={2}>
        <ScrollableMenu restaurants={restaurants.filter((r) => r.active)} />
      </Box>

      {/* 
        <Box mt={2}>
          <ScrollableTags tags={tags} />
        </Box>
      */}

      <Box mt={4} mb={2}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h4">
          {t('restaurants')}
        </Typography>
      </Box>

      <Box>{restaurantsList}</Box>

      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={6} gap={2}>
          <Button
              variant="contained"
              disabled={currentPage === 1}
              onClick={goToPrevPage}
              sx={{
              backgroundColor: '#4caf50',
              color: 'white',
              borderRadius: '20px',
              '&:hover': {
              backgroundColor: '#388e3c',
            },
          }}
          >
          {t('previousPage')}
          </Button>
        <Typography>
          {t('pageXOfY', { current: currentPage, total: totalPages })}
        </Typography>
          <Button
              variant="contained"
            disabled={currentPage === totalPages}
            onClick={goToNextPage}
            sx={{
            backgroundColor: '#4caf50',
            color: 'white',
            borderRadius: '20px',
            '&:hover': {
            backgroundColor: '#388e3c',
          },
        }}
          >
          {t('nextPage')}
          </Button>
        </Box>
      )}
    </Box>
  );
}
