import React, { useState, useEffect } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import GoogleMapReact from 'google-map-react';
import MyAutoComplete from '../components/Map/Autocomplete';
import ToggleButtons from './ToggleButtons';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useCart } from '../context/cart/context';
import { backendUrl } from '../index';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled as styledmui } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import CircularProgress from '@mui/material/CircularProgress';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DateTimePicker } from '@mui/lab';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CopyTextButton from './CopyTextButton';
import Map from './Map/Map';
import Marker from './Map/Marker';

import {
  useFeature,
  CASH_DELIVERY_ONLY,
  DELIVERY_RANGE,
  MIN_ORDER_VALUE
} from '../context/feature/context';

import styled from 'styled-components';
import styles from './../GoogleMapStyles.json';

const Wrapper = styled.main`
  width: 100%;
  height: 200px;
`;

const Offset = styledmui('div')(({ theme }) => theme.mixins.toolbar);

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

function getDistanceBetweenPoints(lat1, lng1, lat2, lng2) {
  const R = 6378137;
  const dLat = degreesToRadians(lat2 - lat1);
  const dLong = degreesToRadians(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

const reducer = (acc, curr) => acc + curr;
export function getTotalSum(list) {
  return list.map((p) => p.quantity * p.price).reduce(reducer, 0);
}

function getTimestamp(timestamp) {
  const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
  const d = new Date(timestamp);
  return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(
    d.getHours()
  )}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}

export default function CollectInfoPage() {
  const { restaurantKey, restaurantId, tableId } = useParams();
  const history = useHistory();
  const restaurant = useLocation().state;

  const [first, setFirst] = useState(true);
  const [value, setValue] = useState(Date.now());
  const [program, setProgram] = useState(false);

  const { state, dispatch } = useCart();
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const { state: featureState } = useFeature();
  const cashDeliveryOnly = featureState?.features?.hasOwnProperty(CASH_DELIVERY_ONLY);
  const deliveryRange = featureState?.features?.hasOwnProperty(DELIVERY_RANGE)
    ? parseInt(featureState.features[DELIVERY_RANGE])
    : 4;
  const minOrderValue =
    restaurantKey && featureState.features.hasOwnProperty(MIN_ORDER_VALUE)
      ? featureState.features[MIN_ORDER_VALUE]
      : 0;

  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState();
  const [mapApi, setMapApi] = useState(null);
  const [center, setCenter] = useState(
    state.userDetails.lat
      ? [state.userDetails.lat, state.userDetails.lng]
      : [parseFloat(restaurant.latitude), parseFloat(restaurant.longitude)]
  );
  const [zoom, setZoom] = useState(10);
  const [address, setAddress] = useState(state.userDetails.address);
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(state.userDetails.lat);
  const [lng, setLng] = useState(state.userDetails.lng);

  useEffect(() => {
    if (state.sessionId) {
      fetch(backendUrl + '/aaaa', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tableId,
          restaurantId,
          restaurantKey,
          userId: state.userId,
          sessionId: state.sessionId,
          page:
            window.location.href +
            '/?userId=' +
            state.userId +
            (state.selected === 'left' ? '/delivery' : '/pickup')
        })
      });
    }
  }, [state.sessionId, state.selected]);

  const distance = getDistanceBetweenPoints(
    lat,
    lng,
    restaurant.latitude,
    restaurant.longitude
  ) / 1000;

  function handleChange(newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    setCurrentLocation();
  }, []);

  function setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setZoom(17);
        setCenter([position.coords.latitude, position.coords.longitude]);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        _generateAddress();
      });
    }
  }


  const RegisterSchemaDelivery = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(10, t('phoneNumberIsTooShort'))
      .required(t('phoneNumberRequired')),
    email: Yup.string().optional().email(t('invalidEmail')),
    firstName: Yup.string().required(t('firstNameRequired')),
    address: Yup.string().required(t('addressRequired')),
    addressdetalis: Yup.string()
  });

  const RegisterSchemaPickup = Yup.object().shape({
    phoneNumber: Yup.string()
      .min(10, t('phoneNumberIsTooShort'))
      .required(t('phoneNumberRequired')),
    email: Yup.string().optional().email(t('invalidEmail')),
    firstName: Yup.string().required(t('firstNameRequired'))
  });

  const formik = useFormik({
    initialValues: {
      ...state.userDetails
    },
    validationSchema: state.selected === 'center' ? RegisterSchemaPickup : RegisterSchemaDelivery,
    onSubmit: async (values) => {
      if (state.selected === 'left') {
        if (distance > deliveryRange) {
          setErrors({
            ...errors,
            address: t('notInCoverageArea')
          });
          return;
        }
      }

      let type = 'ONLINE_DELIVERY';
      if (state.selected === 'center') {
        type = 'ONLINE_PICKUP';
      }

      setIsLoading(true);

      const resp = await fetch(`${backendUrl}/orders/new-order`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          restaurantKey,
          ownerId: state.userId,
          lineItems: state.items,
          type,
          deliveryInfo: {
            ...values,
            address: address + '\nDetalii: ' + values.addressdetalis + '\nProgramata pentru: ' + getTimestamp(value)
          }
        })
      });
      const json = await resp.json();

      dispatch({ type: 'reset' });
      dispatch({ type: 'setOrderId', payload: json.id });
      dispatch({
        type: 'addUserDetails',
        payload: {
          ...values,
          lat,
          lng
        }
      });
      setIsLoading(false);

      if (state.sessionId) {
        fetch(backendUrl + '/aaaa', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            tableId,
            restaurantId,
            restaurantKey,
            userId: state.userId,
            sessionId: state.sessionId,
            page:
              window.location.href +
              '/?userId=' +
              state.userId +
              (state.selected === 'left' ? '/delivery' : '/pickup') +
              '/submit'
          })
        });
      }

      history.push({ pathname: `orders/${json.id}`, state: restaurant });
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setErrors,
    setFieldError
  } = formik;

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setLat(mouse.lat);
    setLng(mouse.lng);
  };
  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
    _generateAddress();
  };

  function _onChange({ center, zoom }) {
    setCenter(center);
    setZoom(zoom);
    _generateAddress();
  }

  function _onClick(value) {
    setLat(value.lat);
    setLng(value.lng);
  }

  function apiHasLoaded(map, maps) {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  }

  function _generateAddress() {
    setFirst(true);
    if (!lat) return;
    if (distance > deliveryRange) {
      setErrors({
        ...errors,
        address: t('notInCoverageArea')
      });
    }
    if (!mapApiLoaded) return;
    if (!mapApi) return;

    let geocoder;
    try {
      geocoder = new mapApi.Geocoder();
      if (!geocoder) return;

      geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            setZoom(15);
            setAddress(results[0].formatted_address);
            setFieldValue('address', results[0].formatted_address, true);
          } else {
            window.alert(t('noResultsFound'));
          }
        } else {
          window.alert(t('geocoderFailedDueTo', { status }));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box>
      <AppBar elevation={0} sx={{ backgroundColor: 'white', color: 'black', height: '10vh' }} position="fixed">
        <Toolbar sx={{ alignItems: 'center' }}>
          <Box ml={!matches ? 1 : 'calc(8px + 33vw)'}>
            <IconButton
              onClick={() => history.goBack()}
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 0 }}
            >
              <ArrowBackIcon sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
          <Box ml={2} justifyContent="center" alignItems="center" minHeight="10vh" display="flex"></Box>
        </Toolbar>
      </AppBar>

      <Offset />

      <Box mt={4} display="flex" flexDirection="column" justifyContent="space-between">
        <Box mb={0} ml={2}>
          <Typography variant="h4" color="inherit" component="h4">
            {t('orderDetails')}
          </Typography>
        </Box>
      </Box>

      <Box mb={3} mt={3}>
        <ToggleButtons />
      </Box>

      {state.selected === 'left' ? (
        <Box ml={1} mr={1}>
          <Box ml={1} mr={1} mt={2}>
            <Typography variant="h5" color="inherit" component="h6">
              {t('pleaseAddDetailsForDelivery')}
            </Typography>
          </Box>

          <Box ml={1} mr={1} mt={1} display="flex" flexDirection="column" justifyContent="space-between">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    label={t('phoneNumberLabel')}
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />

                  <TextField
                    required
                    sx={{ backgroundColor: '#fff' }}
                    fullWidth
                    variant="standard"
                    label={t('fullNameLabel')}
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />

                  <TextField
                    fullWidth
                    variant="standard"
                    type="email"
                    label={t('emailLabel')}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  {mapApiLoaded && (
                    <div>
                      <MyAutoComplete
                        first={first}
                        setFirst={setFirst}
                        distance={distance}
                        address={address}
                        addplace={(place) => {
                          setZoom(15);
                          setCenter([
                            place.geometry.location.lat(),
                            place.geometry.location.lng()
                          ]);
                          setLat(place.geometry.location.lat());
                          setLng(place.geometry.location.lng());
                          _generateAddress();
                        }}
                        otherProps={getFieldProps('address')}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                      />
                    </div>
                  )}

                  <Box mt={1}>
                    <Wrapper>
                      <GoogleMapReact
                        onDrag={(e) => {
                          setLat(e.center.lat);
                          setLng(e.center.lng);
                        }}
                        center={center}
                        defaultZoom={15}
                        draggable={draggable}
                        onChange={_onChange}
                        onChildMouseDown={onMarkerInteraction}
                        onChildMouseUp={onMarkerInteractionMouseUp}
                        onChildMouseMove={onMarkerInteraction}
                        onChildClick={() => console.log('child click')}
                        onClick={_onClick}
                        bootstrapURLKeys={{
                          key: 'AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw',
                          libraries: ['places', 'geometry']
                        }}
                        options={{
                          gestureHandling: 'greedy',
                          styles: styles,
                          disableDefaultUI: true
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => {
                          console.log('Maps loaded');
                          apiHasLoaded(map, maps);
                        }}
                      >
                        <Marker text={address} lat={lat} lng={lng} />
                      </GoogleMapReact>
                    </Wrapper>
                  </Box>

                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography fontWeight="fontWeightBold" variant="h5" mt={3}>
                      {t('pickupTimeASAP')}
                    </Typography>

                    <Box
                      onClick={() => setProgram(false)}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        border: `1px solid ${program ? '#E0E0E0' : ''}`,
                        width: '100%',
                        height: '7vh'
                      }}
                      mt={1}
                    >
                      <Typography fontWeight={!program ? 'bold' : ''} variant="h6">
                        {t('asSoonAsPossible')}
                      </Typography>
                    </Box>

                    <Box
                      mb={2}
                      onClick={() => setProgram(true)}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        border: `1px solid ${!program ? '#E0E0E0' : ''}`,
                        width: '100%',
                        height: '7vh'
                      }}
                      mt={1}
                    >
                      <Typography fontWeight={program ? 'bold' : ''} variant="h6">
                        {t('schedule')}
                      </Typography>
                    </Box>

                    <Stack spacing={2}>
                      {program && (
                        <DateTimePicker
                          value={value}
                          onChange={handleChange}
                          renderInput={(params) => <TextField variant="standard" {...params} />}
                        />
                      )}
                    </Stack>
                  </Box>

                  <Box mt={3}>
                    <TextField
                      fullWidth
                      variant="standard"
                      multiline
                      autoComplete="off"
                      type="text"
                      label={t('addressDetails')}
                      {...getFieldProps('addressdetalis')}
                    />
                  </Box>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box ml={2} mr={2} mt={2}>
            <Typography variant="h6" color="inherit" component="h6">
              {t('orderWillBePickedUpAtRestaurant')}
            </Typography>
          </Box>

          <Box mt={1} ml={2} mr={2}>
            <Stack mb={3} spacing={2}>
              <TextField
                required
                fullWidth
                variant="standard"
                label={t('phoneNumberLabel')}
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />

              <TextField
                required
                sx={{ backgroundColor: '#fff' }}
                fullWidth
                variant="standard"
                label={t('fullNameLabel')}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                variant="standard"
                type="email"
                label={t('emailLabel')}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>

            <Box
              mb={3}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
                <Box mt="4px" mr={1}>
                  <LocationOnOutlinedIcon sx={{ fontSize: 35 }} />
                </Box>
                <Box ml={1} mr={1}>
                  <Typography>
                    <Link
                      sx={{ color: 'blue' }}
                      underline="none"
                      href={`https://www.google.com/maps/place/${restaurant.address}`}
                    >
                      {restaurant.address}
                    </Link>
                  </Typography>
                </Box>
              </Box>
              <Box mr={1}>
                <CopyTextButton text={restaurant.address} />
              </Box>
            </Box>

            <Box mt={1}>
              <Map latR={restaurant.latitude} lngR={restaurant.longitude} />
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography fontWeight="fontWeightBold" variant="h5" mt={3}>
                {t('pickupTimeASAP')}
              </Typography>

              <Box
                onClick={() => setProgram(false)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ border: `1px solid ${program ? '#E0E0E0' : ''}`, width: '100%', height: '7vh' }}
                mt={1}
              >
                <Typography fontWeight={!program ? 'bold' : ''} variant="h6">
                  {t('asSoonAsPossible')}
                </Typography>
              </Box>

              <Box
                mb={2}
                onClick={() => setProgram(true)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ border: `1px solid ${!program ? '#E0E0E0' : ''}`, width: '100%', height:'7vh' }}
                mt={1}
              >
                <Typography fontWeight={program ? 'bold' : ''} variant="h6">
                  {t('schedule')}
                </Typography>
              </Box>

              <Stack spacing={2}>
                {program && (
                  <DateTimePicker
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                  />
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2) < minOrderValue && (
        <Box>
          <Box ml={1} mr={1} mt={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box ml={1} fontWeight="fontWeightBold">
              <Typography variant="h6">{t('productsTotal')}</Typography>
            </Box>
            <Box mr={1} fontWeight="fontWeightBold">
              <Typography variant="body1">
                {(Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2)}{t('currency')}
              </Typography>
            </Box>
          </Box>

          <Box
            ml={1}
            mr={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ml={1}>
              <Typography style={{ color: '#ff0000' }} variant="h6" color="inherit">
                {t('minOrderValue')}
              </Typography>
            </Box>

            <Box mr={1}>
              <Typography style={{ color: '#ff0000' }} variant="h6" color="inherit">
                {minOrderValue}{t('currency')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Box ml={1} mr={1} mt={3} display="flex" alignItems="center" justifyContent="space-between">
        <Box ml={1} fontWeight="fontWeightBold">
          <Typography variant="h4">{t('totalToPay')}</Typography>
        </Box>
        <Box mr={1} fontWeight="fontWeightBold">
          <Typography variant="h5">
            {Math.max(
              minOrderValue,
              (Math.round(getTotalSum(state.items) * 100) / 100).toFixed(2)
            )}{' '}
            {t('currency')}
          </Typography>
        </Box>
      </Box>

      {cashDeliveryOnly && state.selected === 'left' && (
        <Box ml={2} mr={2} mt={2} display="flex" alignItems="center" alignContent="center">
          <InfoOutlined sx={{ color: '#ff0000' }} />
          <Typography variant="body1" ml={1} mt="1px">
            {t('cardNotAvailable')}
          </Typography>
        </Box>
      )}

      <Box sx={{ pb: '190px' }}></Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          left: !matches ? 10 : 'calc(10px + 33vw)',
          right: !matches ? 10 : 'calc(10px + 33vw)'
        }}
        elevation={3}
      >
        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit}
          sx={{ fontWeight: 'fontWeightBold', minHeight: '60px' }}
          size="large"
          variant="contained"
          fullWidth
        >
          {t('order')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
