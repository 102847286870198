import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { backendUrl } from "../index";
import {
  Box,
  Typography,
  List,
  CircularProgress,
  Divider,
  Button
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OrderItem from './OrderItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';

import { useCart } from '../context/cart/context';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export function getTotalSum(list) {
  return list
    .filter(p => p.status !== 'CANCELED')
    .map(
      p =>
        p.quantity *
        (((100 - (p.product.discount || 0)) * p.product.price) / 100)
    )
    .reduce(reducer, 0);
}

function merge(list) {
  const grouped = {};

  for (const lineItem of list) {
    if (grouped[lineItem.miniOrderId] === undefined) {
      grouped[lineItem.miniOrderId] = {};
    }
    if (grouped[lineItem.miniOrderId][lineItem.product.id] === undefined) {
      grouped[lineItem.miniOrderId][lineItem.product.id] = {};
    }
    if (
      grouped[lineItem.miniOrderId][lineItem.product.id][lineItem.status] ===
      undefined
    ) {
      grouped[lineItem.miniOrderId][lineItem.product.id][lineItem.status] = {
        ...lineItem
      };
    } else {
      grouped[lineItem.miniOrderId][lineItem.product.id][lineItem.status].quantity += 1;
    }
  }

  const merged = {};
  for (const miniOrderId in grouped) {
    if (merged[miniOrderId] === undefined) merged[miniOrderId] = [];
    for (const productId in grouped[miniOrderId]) {
      for (const status in grouped[miniOrderId][productId]) {
        merged[miniOrderId].push(
          grouped[miniOrderId][productId][status]
        );
      }
    }
  }
  return merged;
}

const TableOrderDetailsPage = () => {
  const { restaurantId, tableId, orderId, restaurantKey } = useParams();
  const [orderTable, setOrderTable] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const { state, dispatch } = useCart();
  const language = state.language;

  useEffect(() => {
    if (state.sessionId) {
      fetch(backendUrl + '/aaaa', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tableId,
          restaurantId,
          restaurantKey,
          userId: state.userId,
          sessionId: state.sessionId,
          page: window.location.href + "/?userId=" + state.userId
        })
      });
    }
  }, [state.sessionId]);

  async function loadOrderTable() {
    if (tableId && tableId !== "") {
      const response = await fetch(
        backendUrl +
          `/orders/current?tableId=${tableId}` +
          (language === 'ro' ? '' : `&lang=${language}`)
      );
      const json = await response.json();
      if (response.ok) {
        setOrderTable(json);
      }
    } else if (orderId !== "") {
      const response = await fetch(backendUrl + `/orders/${orderId}`);
      const json = await response.json();
      if (response.ok) {
        setOrderTable(json);
      }
    }
  }

  useEffect(() => {
    loadOrderTable();
  }, []);

  const history = useHistory();

  function renderlistItems(list) {
    return Object.entries(merge(list)).map(([miniOrderId, products], index) => (
      <React.Fragment key={miniOrderId}>
        {index !== 0 && <Divider />}
        {products.map(product => (
          <OrderItem
            key={product.id + product.status}
            orderType={orderTable.type}
            lineItem={product}
          />
        ))}
      </React.Fragment>
    ));
  }

  if (!orderTable)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <AppBar
            elevation={0}
            sx={{ backgroundColor: 'white', color: 'black', height: '10vh' }}
            color="default"
            position="fixed"
          >
            <Toolbar sx={{ alignItems: 'center' }}>
              <Box ml={!matches ? 1 : 'calc(8px + 33vw)'}>
                <IconButton
                  onClick={() => {
                    restaurantKey
                      ? history.push(`/${restaurantKey}`)
                      : history.push(`/restaurants/${restaurantId}/tables/${tableId}`);
                  }}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <ArrowBackIcon sx={{ fontSize: 35 }} />
                </IconButton>
              </Box>
              <Box ml={0} justifyContent="center" alignItems="center" minHeight="100vh" display="flex">
              </Box>
            </Toolbar>
          </AppBar>
          <Offset />

          <Box mt={4} display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Box mb={0} ml={2}>
                <Typography variant="h4" color="inherit" component="h4">
                  {t('order')}
                </Typography>
              </Box>
            </Box>
          </Box>

          {orderTable.type !== 'TABLE' && (
            <Box ml={2} mt={1} display="flex" flexDirection="row">
              <Typography variant="h5" color="inherit" component="h5">
                {t('status')}:
              </Typography>

              {orderTable.status === 'OPEN' && (
                <Typography sx={{ fontWeight: 'bold' }} ml={1} variant="h5" color="#inherit" component="h4">
                  {t('received')}
                </Typography>
              )}

              {orderTable.status === 'PREPARING' && (
                <Typography sx={{ fontWeight: 'bold' }} ml={1} variant="h5" color="#inherit" component="h5">
                  {t('inPreparation')}
                </Typography>
              )}

              {orderTable.status === 'READY_FOR_DELIVER_OR_PICKUP' &&
                orderTable.type === 'ONLINE_PICKUP' && (
                  <Typography sx={{ fontWeight: 'bold' }} ml={1} variant="h5" color="#inherit" component="h5">
                    {t('readyToPickup')}
                  </Typography>
                )}

              {orderTable.status === 'READY_FOR_DELIVER_OR_PICKUP' &&
                orderTable.type === 'ONLINE_DELIVERY' && (
                  <Typography sx={{ fontWeight: 'bold' }} ml={1} variant="h5" color="#inherit" component="h5">
                    {t('beingDelivered')}
                  </Typography>
                )}

              {orderTable.status === 'CLOSED' && (
                <Typography sx={{ fontWeight: 'bold' }} ml={1} variant="h5" color="#00c853" component="h5">
                  {t('delivered')}
                </Typography>
              )}
            </Box>
          )}

          <List>{renderlistItems(orderTable.items)}</List>

          <Box
            sx={{ pb: '90px' }}
            ml={1}
            mr={2}
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">{t('total')}</Typography>
            <Typography variant="h5">
              {(Math.round(getTotalSum(orderTable.items) * 100) / 100).toFixed(2)} {t('currency')}
            </Typography>
          </Box>

          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              left: !matches ? 10 : 'calc(10px + 33vw)',
              right: !matches ? 10 : 'calc(10px + 33vw)'
            }}
            elevation={3}
          >
            <Button
              onClick={() => {
                restaurantKey
                  ? history.push(`/${restaurantKey}`)
                  : history.push(`/restaurants/${restaurantId}/tables/${tableId}`);
              }}
              sx={{ minHeight: '60px', fontWeight: 'fontWeightBold' }}
              size="large"
              variant="contained"
              fullWidth
            >
              {t('goBackToMenu')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TableOrderDetailsPage;