import LocationOn from '@mui/icons-material/LocationOn';
import PlaceIcon from '@mui/icons-material/Place';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, AppBar, Box, InputBase, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 15,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  marginRight: theme.spacing(2),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export default function SearchAppBar({
  onSearch,
  onLocationUpdate,
  onGeolocationStatusChange,
}) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState(t('determiningLocation'));
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [manualSelection, setManualSelection] = useState(false);
  const [locationTerm, setLocationTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loadingLocation, setLoadingLocation] = useState(true);

  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const prevPosition = useRef({ lat: null, lng: null });
  const permissionCheckInterval = useRef(null);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCqQdf2lCuY7g23ax7iEr93AeiA0hOF6zw&libraries=places`;
      script.async = true;
      script.onload = () => {
        if (window.google && window.google.maps && window.google.maps.places) {
          initAutocompleteServices();
        }
        checkIfAutoLocationExists();
      };
      document.head.appendChild(script);
    } else {
      if (window.google && window.google.maps && window.google.maps.places) {
        initAutocompleteServices();
      }
      checkIfAutoLocationExists();
    }

    return () => {
      if (permissionCheckInterval.current) {
        clearInterval(permissionCheckInterval.current);
      }
    };
  }, []);

  const checkIfAutoLocationExists = async () => {
    const autoLocation = getLocalStorage('autoLocation');
    if (autoLocation) {
      const permissionGranted = await checkGeolocationPermission();
      if (permissionGranted) {
        const {
          latitude,
          longitude,
          cityName,
          countryName,
          formattedAddress,
        } = autoLocation;

        setLat(latitude);
        setLong(longitude);
        setCity(cityName);
        setCountry(countryName);
        setLocation(formattedAddress);
        setManualSelection(false);
        setLoadingLocation(false);

        if (onLocationUpdate) {
          onLocationUpdate({ latitude, longitude });
        }

        prevPosition.current = { lat: latitude, lng: longitude };

        startPermissionCheckInterval();
      } else {
        deleteLocalStorage('autoLocation');
        startManualFlow();
      }
    } else {
      const permissionGranted = await checkGeolocationPermission();
      if (permissionGranted) {
        getGeolocation();
      } else {
        getGeolocation();
      }
    }
  };

  const checkGeolocationPermission = async () => {
    if (navigator.permissions && navigator.permissions.query) {
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        return result.state === 'granted';
      } catch (err) {
        console.error('Error querying geolocation permissions:', err);
        return false;
      }
    } else {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          () => resolve(true),
          () => resolve(false),
          { timeout: 3000 }
        );
      });
    }
  };

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          handleGeolocationSuccess(latitude, longitude);
        },
        (error) => {
          console.error('Geolocation error:', error);
          startManualFlow();
        },
        { enableHighAccuracy: true, timeout: 5000 }
      );
    } else {
      console.error(t('geolocationNotSupported'));
      startManualFlow();
    }
  };

  const handleGeolocationSuccess = (latitude, longitude) => {
    setLat(latitude);
    setLong(longitude);

    if (onGeolocationStatusChange) {
      onGeolocationStatusChange(true);
    }

    if (!window.google || !window.google.maps) {
      setLocation(t('locationUnknown'));
      setLoadingLocation(false);
      return;
    }

    geocodePosition(latitude, longitude, (cityName, countryName, address) => {
      setCity(cityName);
      setCountry(countryName);
      setLocation(address);
      setLoadingLocation(false);

      const autoLocData = {
        latitude,
        longitude,
        cityName,
        countryName,
        formattedAddress: address,
      };
      setLocalStorage('autoLocation', autoLocData);

      if (onLocationUpdate) {
        onLocationUpdate({ latitude, longitude });
      }

      prevPosition.current = { lat: latitude, lng: longitude };

      startPermissionCheckInterval();
    });
  };

  const startManualFlow = () => {
    deleteLocalStorage('autoLocation');
    setManualSelection(false);

    const savedLocation = getLocalStorage('manualLocation');
    if (savedLocation) {
      const { latitude, longitude, cityName, countryName, formattedAddress } =
        savedLocation;
      setLat(latitude);
      setLong(longitude);
      setCity(cityName);
      setCountry(countryName);
      setLocation(formattedAddress);
      setLocationTerm(formattedAddress);
      setManualSelection(true);
      setLoadingLocation(false);

      if (onLocationUpdate) {
        onLocationUpdate({ latitude, longitude });
      }
    } else {
      setLocation(t('cannotDetermineLocation'));
      setManualSelection(true);
      setLoadingLocation(false);
    }
    startPermissionCheckInterval();
  };

  const startPermissionCheckInterval = () => {
    if (permissionCheckInterval.current) {
      clearInterval(permissionCheckInterval.current);
    }

    permissionCheckInterval.current = setInterval(async () => {
      const permissionGranted = await checkGeolocationPermission();
      if (permissionGranted) {
        const autoLocation = getLocalStorage('autoLocation');
        if (autoLocation) {
          const { latitude, longitude } = autoLocation;
          if (
            latitude !== prevPosition.current.lat ||
            longitude !== prevPosition.current.lng
          ) {
          }
        } else {
          getGeolocation();
        }
      } else {
        const autoLocation = getLocalStorage('autoLocation');
        if (autoLocation) {
          deleteLocalStorage('autoLocation');
          startManualFlow();
        }
      }
    }, 5000);
  };

  const geocodePosition = (latitude, longitude, callback) => {
    if (!window.google || !window.google.maps) {
      callback(t('cityUnknown'), t('countryUnknown'), t('locationUnknown'));
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK' && results[0]) {
          const addressComponents = results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes('locality')
          );
          const countryComponent = addressComponents.find((component) =>
            component.types.includes('country')
          );

          const cityName = cityComponent
            ? cityComponent.long_name
            : t('cityUnknown');
          const countryName = countryComponent
            ? countryComponent.long_name
            : t('countryUnknown');
          const formattedAddress = `${cityName}, ${countryName}`;

          callback(cityName, countryName, formattedAddress);
        } else {
          callback(t('cityUnknown'), t('countryUnknown'), t('locationUnknown'));
        }
      }
    );
  };

  const initAutocompleteServices = () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      return;
    }
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    setLocationTerm(value);
    if (value.length > 2 && autocompleteService.current) {
      fetchAutocompleteSuggestions(value);
    }
  };

  const fetchAutocompleteSuggestions = (input) => {
    if (!autocompleteService.current) return;
    const request = { input };
    autocompleteService.current.getPlacePredictions(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSuggestions(results);
      }
    });
  };

  const handleSelectSuggestion = (placeId) => {
    if (placesService.current) {
      placesService.current.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setLocationTerm(place.formatted_address);
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          const cityName =
            place.address_components.find((c) =>
              c.types.includes('locality')
            )?.long_name || '';
          const countryName =
            place.address_components.find((c) =>
              c.types.includes('country')
            )?.long_name || '';

          setLat(latitude);
          setLong(longitude);
          setCity(cityName);
          setCountry(countryName);
          setSuggestions([]);

          setLocation(place.formatted_address);

          if (manualSelection) {
            const manualLocData = {
              latitude,
              longitude,
              cityName,
              countryName,
              formattedAddress: place.formatted_address,
            };
            setLocalStorage('manualLocation', manualLocData);
          }

          if (onLocationUpdate) {
            onLocationUpdate({
              latitude,
              longitude,
            });
          }
        }
      });
    }
  };

  return (
    <Box>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'white', color: 'black', padding: '8px 5px' }}
        position="static"
      >
        <Box display="flex" alignItems="center">
          <LocationOn />
          {loadingLocation ? (
            <Box ml={1} display="flex" alignItems="center">
              <CircularProgress size={20} />
              <Typography ml={2} variant="h5">
                {t('determiningLocation')}
              </Typography>
            </Box>
          ) : (
            <Typography ml={1} sx={{ fontWeight: 'bold' }} variant="h5">
              {city && country ? `${city}, ${country}` : location}
            </Typography>
          )}
        </Box>
      </AppBar>

      <Box mt={2}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={t('searchRestaurants')}
            inputProps={{ 'aria-label': 'search' }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Search>
      </Box>

      {manualSelection && (
        <Box mt={2} position="relative">
          <Search>
            <SearchIconWrapper>
              <PublicIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('searchLocation')}
              inputProps={{ 'aria-label': 'location' }}
              value={locationTerm}
              onChange={handleLocationChange}
            />
          </Search>

          {suggestions.length > 0 && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                zIndex: 10,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              {suggestions.map((suggestion) => (
                <Box
                  key={suggestion.place_id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #ccc',
                    '&:hover': {
                      backgroundColor: alpha('#3f51b5', 0.1),
                    },
                  }}
                  onClick={() => handleSelectSuggestion(suggestion.place_id)}
                >
                  <PlaceIcon sx={{ color: '#3f51b5', marginRight: '8px' }} />
                  <Typography variant="body2">
                    {suggestion.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
