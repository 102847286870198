import React, { useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CopyTextButton({ text }) {
  const [copiedText, setCopiedText] = useState(false);

  const { t } = useTranslation();

  async function copyTextToClipboard(str) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(str);
    } else {
      return document.execCommand("copy", true, str);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(text)
      .then(() => {
        setCopiedText(true);
        setTimeout(() => {
          setCopiedText(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Tooltip
      title={t('copied')}
      placement="top"
      open={copiedText}
    >
      <ContentCopyIcon onClick={handleCopyClick} sx={{ cursor: 'pointer' }} />
    </Tooltip>
  );
}
