import React, { useState } from "react";
import { Paper, Avatar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Box, Typography } from "@mui/material";
import useDrag from "./useDrag";
import "./NoScrollBar.css";

import { useCart } from '../../context/cart/context';
import { useHistory } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

export default function ScrollableProductsCategory({
  restaurant,
  restaurantId,
  restaurantKey,
  tableId,
  hasOrderModule,
  products
}) {
  const { state, dispatch } = useCart();
  const history = useHistory();
  const theme = useTheme();

  const { t } = useTranslation();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  function handleOnClick(product) {
    const isAvailable = product.available || product.available === undefined;
    if (isAvailable) {
      dispatch({ type: 'setPosition', payload: window.pageYOffset });
      history.push({
        pathname:
          (restaurantKey
            ? `/${restaurantKey}`
            : `/restaurants/${restaurantId}/tables/${tableId}`) +
          `/dish-details/${product.id}`,
        state: { product, hasOrderModule }
      });
    }
  }

  const filteredProducts = products
    .filter((p) => restaurantKey || !p.tags.some((tag) => tag.value === "DeliveryOnly"))
    .filter((p) => p.available || p.available === undefined);

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }) => (ev) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  return (
    <Box mt={0} mb={1}>
      <div onMouseLeave={dragStop}>
        <ScrollMenu
          onWheel={onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          scrollContainerClassName="NoScrollBar"
        >
          {filteredProducts.map((product) => (
            <Box
              ml={1}
              key={product.id}
              onClick={() => handleOnClick(product)}
              sx={{ width: !matches ? "30vw" : "10vw" }}
            >
              <Paper
                sx={{
                  borderTopLeftRadius: 15,
                  borderBottomRightRadius: 15,
                  borderBottomLeftRadius: 15,
                  borderTopRightRadius: 15
                }}
                elevation={5}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ backgroundColor: 'white', borderRadius: 100 }}
                >
                  <Avatar
                    sx={{
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      zIndex: 0,
                      width: "100%",
                      height: !matches ? "25vw" : "10vw"
                    }}
                    src={product.imgUrl + "?tr=h-300"}
                  />
                </Box>

                <Box>
                  <Box mr={1} ml={1} mt={1}>
                    <Typography
                      sx={{
                        fontWeight: "fontWeightBold",
                        width: "100%",
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      variant="body2"
                    >
                      {product.name}
                    </Typography>
                  </Box>
                  <Box mr={1} ml={1}>
                    <Typography
                      sx={{
                        width: "100%",
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      variant="body2"
                    >
                      {product.description}
                    </Typography>
                  </Box>

                  <Box mr={1} mb={3} ml={1} display="flex">
                    <Box mb={1} mr={1}>
                      {!product.discount || product.discount === 0 ? (
                        <Typography variant="body2">
                          {product.price} {t('currency')}
                        </Typography>
                      ) : (
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Typography variant="body2">
                            {(100 - product.discount) * product.price / 100} {t('currency')}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          ))}
        </ScrollMenu>
      </div>
    </Box>
  );
}

function onWheel(apiObj, ev) {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isTouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
