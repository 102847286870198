import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Paper, Box, Avatar, Button, Chip } from "@mui/material";
import { useFeature, COLOR, BANNER } from "../context/feature/context";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

export default function Banner() {
  const { t } = useTranslation();
  const { state: featureState } = useFeature();
  const color = featureState.features?.[COLOR] || "#000";

  let banner = null;
  try {
    banner = featureState.features?.[BANNER] ? JSON.parse(featureState.features[BANNER]) : null;
  } catch (error) {
    console.error("Invalid JSON for banner:", error);
  }

  const [open, setOpen] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, [open]);

  function handleOnClick() {
    if (banner?.bannerGoToLink) {
      window.open(banner.bannerGoToLink, "_blank");
    }
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  if (!banner) return null;

  return (
    <Box sx={{ borderRadius: 0 }} onClick={handleOnClick}>
      <Box display="flex" justifyContent="center" sx={{ borderRadius: 0 }}>
        <Box mt={1}>
          <Chip
            size="small"
            variant="outlined"
            clickable
            style={{
              position: "absolute",
              left: !matches ? "5%" : "calc(5% + 31vw)",
              zIndex: 10,
              backgroundColor: "#b71c1c",
              border: "0px",
              color: "#fff",
              fontSize: "10px",
              fontWeight: "bold",
            }}
            label={t('sponsored')}
          />
        </Box>

        <Box mt="113px">
          <Button
            variant="outlined"
            style={{
              position: "absolute",
              right: "4%",
              zIndex: 10,
              backgroundColor: "#fff",
              border: "0px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 12 }}>
              {banner.bannerButton}
            </Typography>
          </Button>
        </Box>

        <Box mt="55px" sx={{ position: "absolute", zIndex: 1000 }}>
          <Typography align="left" variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
            {banner.bannerText}
          </Typography>
        </Box>

        <Avatar
          sx={{
            filter: "brightness(70%)",
            borderRadius: 5,
            zIndex: 0,
            width: !matches ? "95vw" : "31vw",
            height: "150px",
          }}
          src={banner.bannerImg}
        />
      </Box>
    </Box>
  );
}
